import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChangeRequestStatus, DubPreviewDetails } from "./types";
import { dubThunks } from "@/reducers/thunks/dub";
import { STATUS } from "@/constants/status";
import { LANGUAGES } from "@/constants/languages";
import { DubPreviewTopBarVariant } from "@/features/preview/constants/config";
import { BASE_VERSION } from "@/features/clientProjectDetails";
import { DUB_STATUS, TASK_STATUS } from "@/types/project";
import { UNKNOWN_ERROR_MESSAGE } from "@/constants/errors";
import { issueCommentThunk } from "@/reducers/thunks/issueCommentThunk";

export enum PreviewPlayer {
  "Original",
  "Dubbed"
}

export interface DubPreviewSliceState {
  dubDetails: DubPreviewDetails;
  dubIssues: any;
  dubPreviewApiStatus: STATUS;
  changeRequestApiStatus: STATUS;
  apiFailureReason: string;
  activeBlockId: string | null;
  topBarVariant?: DubPreviewTopBarVariant;
  currentPlayer: PreviewPlayer;
}

const dubPreviewInitialState: DubPreviewSliceState = {
  dubDetails: {
    dubId: "",
    fileId: "",
    fileName: "",
    projectName: "",
    lastUpdated: 0,
    sourceLocale: LANGUAGES.ENGLISH,
    language: LANGUAGES.ENGLISH,
    blocks: [],
    downloadUrl: "",
    originalAudioUrl: "",
    qcEnabled: false,
    lipSyncEnabled: false,
    dubVersion: BASE_VERSION,
    dubVersions: [],
    dubStatus: DUB_STATUS.WAITING_FOR_UPLOAD,
    changeRequestData: undefined
  },
  dubIssues: [],
  dubPreviewApiStatus: STATUS.IDLE,
  changeRequestApiStatus: STATUS.IDLE,
  apiFailureReason: "",
  activeBlockId: null,
  topBarVariant: "default",
  currentPlayer: PreviewPlayer.Dubbed
};

export const dubPreviewSlice = createSlice({
  name: "dubPreview",
  initialState: dubPreviewInitialState as DubPreviewSliceState,
  reducers: {
    updateDubDetails: (
      state,
      action: PayloadAction<Partial<DubPreviewDetails>>
    ) => {
      const data = action.payload;
      const newBlocks = data.blocks?.map((block, index) => {
        const nextBlock =
          data.blocks && index !== data.blocks.length - 1
            ? data.blocks[index + 1]
            : null;
        return {
          ...block,
          original: {
            ...block.original,
            endTime: nextBlock
              ? nextBlock.original.startTime - 0.001
              : block.original.startTime + block.original.duration
          }
        };
      });

      if (newBlocks && newBlocks[0]?.original?.startTime !== 0) {
        newBlocks[0].original.startTime = 0;
      }
      if (newBlocks) {
        data.blocks = newBlocks;
      }
      // @TODO: check if this is causing any issues
      state.dubDetails = {
        ...state.dubDetails,
        ...data
      };
    },
    qcMarkAsDone: (state) => {
      state.dubDetails.externalTaskStatus = TASK_STATUS.COMPLETED;
    },
    qcSendToCustomer: (state) => {
      state.dubDetails.dubStatus = DUB_STATUS.COMPLETED;
    },
    updateDubPreviewApiStatus: (state, action: PayloadAction<STATUS>) => {
      state.dubPreviewApiStatus = action.payload;
    },
    updateActiveBlockId: (state, action: PayloadAction<string | null>) => {
      state.activeBlockId = action.payload;
    },
    clearDubDetails: (state) => {
      state.activeBlockId = dubPreviewInitialState.activeBlockId;
      state.dubDetails = dubPreviewInitialState.dubDetails;
      state.dubPreviewApiStatus = dubPreviewInitialState.dubPreviewApiStatus;
      state.changeRequestApiStatus =
        dubPreviewInitialState.changeRequestApiStatus;
      state.dubIssues = dubPreviewInitialState.dubIssues;
    },
    updateTopBarVariant: (
      state,
      action: PayloadAction<DubPreviewTopBarVariant | undefined>
    ) => {
      state.topBarVariant = action.payload;
    },
    setPreviewPlayer: (state, action: PayloadAction<PreviewPlayer>) => {
      state.currentPlayer = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(dubThunks.fetchDubPreview.pending, (state) => {
        state.dubPreviewApiStatus = STATUS.LOADING;
      })
      .addCase(dubThunks.fetchDubPreview.fulfilled, (state) => {
        state.dubPreviewApiStatus = STATUS.SUCCESS;
      })
      .addCase(dubThunks.fetchDubPreview.rejected, (state) => {
        state.dubPreviewApiStatus = STATUS.ERROR;
      })
      .addCase(dubThunks.fetchDubPreviewInternal.pending, (state) => {
        state.dubPreviewApiStatus = STATUS.LOADING;
      })
      .addCase(dubThunks.fetchDubPreviewInternal.fulfilled, (state, action) => {
        const data: DubPreviewDetails = action.payload;
        const newBlocks = data.blocks?.map((block, index) => {
          const nextBlock =
            index !== data.blocks.length - 1 ? data.blocks[index + 1] : null;

          const changeRequestData =
            state.dubDetails.changeRequestData?.dubId === data.dubId
              ? state.dubDetails.changeRequestData
              : undefined;

          // console.log(
          //   changeRequestData,
          //   state.dubDetails.changeRequestData,
          //   data,
          //   state.dubDetails.changeRequestData?.dubId === data.dubId
          // );
          return {
            ...block,
            original: {
              ...block.original,
              endTime: nextBlock
                ? nextBlock.original.startTime - 0.001
                : block.original.startTime + block.original.duration
            },
            changeRequestData
          };
        });

        if (newBlocks && newBlocks[0]?.original?.startTime !== 0) {
          newBlocks[0].original.startTime = 0;
        }
        data.blocks = newBlocks;
        state.dubDetails = data;
        state.dubDetails.fileId = action.meta.arg.fileId;
        state.dubDetails.workspaceId = action.meta.arg.workspaceId;
        state.dubPreviewApiStatus = STATUS.SUCCESS;
      })
      .addCase(
        dubThunks.fetchDubPreviewInternal.rejected,
        (state, action: PayloadAction<any>) => {
          state.apiFailureReason =
            action.payload?.extra || UNKNOWN_ERROR_MESSAGE;

          state.dubPreviewApiStatus = STATUS.ERROR;
        }
      )
      .addCase(
        dubThunks.fetchDraftVersion.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.dubDetails.draftDubVersion = action.payload;
        }
      )
      .addCase(issueCommentThunk.requestChanges.fulfilled, (state, action) => {
        if (action.payload.responseCode === STATUS.SUCCESS) {
          const updatedData = action.payload.responseData;
          state.dubDetails.changeRequestData = updatedData;
          state.dubDetails.changeRequestCount = state.dubDetails
            .changeRequestCount
            ? state.dubDetails.changeRequestCount + 1
            : 1;
        }
      })
      .addCase(issueCommentThunk.getChangeRequestData.pending, (state) => {
        state.dubDetails.changeRequestData = undefined;
        state.changeRequestApiStatus = STATUS.LOADING;
      })
      .addCase(
        issueCommentThunk.getChangeRequestData.fulfilled,
        (state, action) => {
          state.dubDetails.changeRequestData = action.payload.responseData;
          state.changeRequestApiStatus = STATUS.SUCCESS;
        }
      )
      .addCase(issueCommentThunk.getChangeRequestData.rejected, (state) => {
        state.dubDetails.changeRequestData = undefined;
        state.changeRequestApiStatus = STATUS.ERROR;
      })
      .addCase(dubThunks.reOpenDub.fulfilled, (state, action) => {
        const { dubId } = action.meta.arg;
        if (
          state.dubDetails.dubId === dubId &&
          state.dubDetails.changeRequestData
        ) {
          state.dubDetails.changeRequestData = {
            ...state.dubDetails.changeRequestData,
            status: ChangeRequestStatus.IN_REVIEW
          };
        }
      });
  }
});

export const {
  updateDubDetails,
  updateDubPreviewApiStatus,
  updateActiveBlockId,
  clearDubDetails,
  updateTopBarVariant,
  qcMarkAsDone,
  qcSendToCustomer,
  setPreviewPlayer
} = dubPreviewSlice.actions;

export default dubPreviewSlice.reducer;
