import { userManagementApi } from "@/features/user";
import { userStateApi } from "@/features/userState/api";
import { createAppAsyncThunk, errorCheck } from "@/utils/reduxUtils";
import { storageService } from "@/utils/storage";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const userThunks = {
  fetchUserState: createAsyncThunk("fetchUserState", async () => {
    if (!storageService.getItem("countryCode")) {
      const countryCodeRes = await userStateApi.getVisitorRegion();
      const countryCode = countryCodeRes?.data?.countryCode;
      storageService.setItem("countryCode", countryCode);
    }
    const response = await userStateApi.get();
    return response.data.responseData;
  }),
  fetchWorkspaceState: createAppAsyncThunk(
    "fetchWorkspaceState",
    async () => {
      const response = await userStateApi.fetchWorkspaceState();
      return response.data.responseData;
    },
    {
      condition: (_, { getState }) => {
        const userState = getState().user;

        if (userState.linkedWorkspaces.length <= 0) {
          return false;
        }

        return true;
      }
    }
  ),
  fetchInternalUsers: createAppAsyncThunk(
    "fetchInternalUsers",
    async (_, { rejectWithValue, signal }) => {
      try {
        const response = await userManagementApi.fetchInternalUsers(signal);
        return response.data.responseData;
      } catch (error: any) {
        return rejectWithValue(error);
      }
    }
  ),
  fetchVisitorRegion: createAsyncThunk(
    "fetchVisitorRegion",
    async (_, thunkAPI) => {
      return errorCheck(async () => {
        const response = await userStateApi.getVisitorRegion();
        return response.data;
      }, [thunkAPI]);
    }
  )
};
