import { api, JSON_TYPE } from "@/lib/axios";
import {
  AutoPayData,
  PreviewData,
  PricingPlan,
  PricingPreviewPayload,
  PricingUpgradePayload
} from "../types";
import { APIResponse } from "@/types/apiResponse";
import { storageService } from "@/utils/storage";

export const pricingApi = {
  fetchPricingPlans: () =>
    api.get<
      APIResponse<{
        planCategories: PricingPlan[];
      }>
    >("ping/dub-pricing-plans"),

  fetchPricingPreview: ({
    pricingPlan,
    topupValue,
    abortController,
    billingCity,
    billingCountry,
    billingPostalCode,
    billingState,
    taxType,
    taxValue
  }: PricingPreviewPayload) =>
    api.get<PreviewData>(`stripe/upgrade-plan-preview/${pricingPlan}`, {
      params: {
        pricingPlan,
        quantity: topupValue,
        billingCity: billingCity || undefined,
        billingCountry: billingCountry || undefined,
        billingPostalCode: billingPostalCode || undefined,
        billingState: billingState || undefined,
        taxType: taxType || undefined,
        taxValue: taxValue || undefined
      },
      headers: {
        "CloudFront-Viewer-Country":
          window.countryCode || storageService.getItem("countryCode") || "US" // @TODO: Add the Actual Country Code
      },
      signal: abortController?.signal
    }),

  upgrade: ({
    pricingPlan,
    topupValue,
    paymentMethod,
    taxType,
    upgradePreviewGeneratedUsingInAccount,
    businessName,
    taxValue,
    couponCode
  }: PricingUpgradePayload) =>
    api.post(`stripe/upgrade-plan/${pricingPlan}`, null, {
      params: {
        quantity: topupValue,
        paymentMethod,
        couponCode: couponCode || undefined,
        taxType: taxType || undefined,
        taxValue: taxValue || undefined,
        upgradePreviewGeneratedUsingInAccount,
        businessName: businessName || undefined
      },
      headers: {
        "Content-Type": JSON_TYPE
      }
    }),
  updateAutoPayData: (data: AutoPayData) =>
    api.put<APIResponse<{ workspaceId: string | null }>>(
      "/workspace/auto-pay",
      data
    )
};
