import {
  useMemo,
  useReducer,
  createContext,
  useContext,
  useRef,
  // useEffect,
  Dispatch,
  useEffect
} from "react";
import {
  ProgressiveAudioPlayerStateType,
  ProgressiveAudioPlayerActionType,
  ProgressiveAudioPlayerMapType,
  ProgressiveAudioPlayerContextType,
  ProgressiveAudioPlayerProviderProps,
  ProgressiveAudioDataType,
  MediaPlayerSeekToParams
} from "../../types";
import { getAudioDataFromTimestamp, generateAudioInstance } from "./util";

const ProgressiveAudioPlayerContexts: ProgressiveAudioPlayerMapType = {};

const BUFFER_TIME = 30;

function ProgressiveAudioPlayerProvider({
  id,
  children,
  initialMuted = false,
  ...props
}: ProgressiveAudioPlayerProviderProps) {
  const audioRefs = useRef<Record<number, HTMLAudioElement>>({});
  const currentAudioRefIndex = useRef<number>(0);
  const initialState: ProgressiveAudioPlayerStateType = {
    audioData: [],
    isPlaying: false,
    buffering: false,
    renderBuffering: false,
    volume: 1,
    totalDuration: 0,
    playableDuration: 0,
    mediaProgress: 0,
    setupDone: false,
    muted: initialMuted,
    playRetry: false,
    isPlayingWithBuffer: false
  };

  // console.log(audioRefs.current);

  const onTimeUpdatedCallback = (e: Event, index: number) => {
    const audioEl = e.target as HTMLAudioElement;
    if (!isNaN(audioEl.duration)) {
      const timeLeft = audioEl.duration - audioEl.currentTime;
      if (timeLeft < BUFFER_TIME && audioEl.networkState === 1) {
        // current audio element is about to end, load the next one
        loadAudio(index + 1);
      }

      updateMediaProgress(e, index);
    }
  };
  const onEndedCallback = (e: Event, index: number) => {
    const audioEl = e.target as HTMLAudioElement;
    if (audioEl && audioEl.pause) {
      // console.log("PAUSE 1 - ", index);
      audioEl.pause();
    }
    updateCurrentAudioRefIndex(index + 1);
  };
  // const onEndedCallback = () => {};
  const onBufferCallback = (e: Event, index: number, buffering: boolean) => {
    handleBuffer(e, index, buffering);
  };

  function progressiveMediaPlayerReducer(
    state: ProgressiveAudioPlayerStateType,
    action: ProgressiveAudioPlayerActionType
  ) {
    switch (action.type) {
      case "setup": {
        const { audioData, totalDuration } = action.payload;
        audioRefs.current[0] = generateAudioInstance({
          url: audioData[0].url,
          index: 0,
          onTimeUpdatedCallback,
          onEndedCallback,
          onBufferCallback,
          muted: state.muted
        });
        audioRefs.current[0].preload = "auto";

        return {
          ...state,
          audioData,
          totalDuration,
          setupDone: true
        };
      }
      case "updateAudioData": {
        // should only be called after render
        const newAudioData: ProgressiveAudioDataType[] =
          action.payload.audioData;

        // newAudioData is the list of audioData that has been updated
        // each item in newAudioData has a "position" property that indicates the index of the audioData in state.audioData
        // map through the list and update the audioData in state

        const audioDataBuffer = [...state.audioData];

        newAudioData.forEach((newAudio) => {
          if (newAudio.position === undefined) {
            audioDataBuffer.push(newAudio);
          } else {
            if (
              audioRefs.current[newAudio.position] ||
              newAudio.position === currentAudioRefIndex.current + 1
            ) {
              if (
                newAudio.position < currentAudioRefIndex.current - 1 ||
                newAudio.position > currentAudioRefIndex.current + 5
              ) {
                // an old audio is updated and seeker is past playing it
                delete audioRefs.current[newAudio.position];
              } else {
                // audio instance that is equal to or ahead of the current audio
                delete audioRefs.current[newAudio.position];
                // console.log("GEN 2 - ", newAudio.position);
                audioRefs.current[newAudio.position] = generateAudioInstance({
                  url: newAudio.url,
                  index: newAudio.position,
                  onTimeUpdatedCallback,
                  onEndedCallback,
                  onBufferCallback,
                  muted: state.muted,
                  volume: state.volume
                });
                if (currentAudioRefIndex.current === newAudio.position) {
                  const audioInstanceTimeStampData = getAudioDataFromTimestamp({
                    audioData: state.audioData, // the durations would be similar, so we can use the old audioData
                    timestamp: state.mediaProgress
                  });
                  audioRefs.current[newAudio.position].currentTime =
                    audioInstanceTimeStampData.audioProgress;
                }
                if (currentAudioRefIndex.current <= newAudio.position + 2) {
                  if (
                    newAudio.position === currentAudioRefIndex.current &&
                    state.isPlaying
                  ) {
                    // console.log("PLAY 4 - ", newAudio.position);
                    audioRefs.current[newAudio.position].play();
                  }
                  if (audioRefs.current[newAudio.position].networkState < 2) {
                    // console.log("LOAD 1 - ", newAudio.position);
                    audioRefs.current[newAudio.position].load();
                  }
                }
              }
            }

            audioDataBuffer[newAudio.position] = newAudio;
          }
        });

        return {
          ...state,
          audioData: audioDataBuffer
        };
      }
      case "setCurrentAudioRefIndex": {
        if (action.payload !== currentAudioRefIndex.current) {
          if (state.isPlaying) {
            if (audioRefs.current[action.payload]) {
              // console.log("PAUSE 2 - ", currentAudioRefIndex.current);
              if (audioRefs.current[currentAudioRefIndex.current]) {
                audioRefs.current[currentAudioRefIndex.current].pause();
              }
              audioRefs.current[action.payload].currentTime = 0;
              audioRefs.current[action.payload].volume = state.volume;
              audioRefs.current[action.payload].muted = state.muted;
              // console.log(
              //   "PLAY 1 - ",
              //   action.payload,
              //   "ReadyState:  ",
              //   audioRefs.current[action.payload].readyState
              // );
              audioRefs.current[action.payload].play();
            }
          }
          currentAudioRefIndex.current = action.payload;
        }

        return state;
      }
      case "play": {
        const audioRef = audioRefs.current[currentAudioRefIndex.current];
        if (!audioRef || audioRef === undefined) {
          // console.log("GEN 3 - ", currentAudioRefIndex.current);
          audioRefs.current[currentAudioRefIndex.current] =
            generateAudioInstance({
              url: state.audioData[currentAudioRefIndex.current].url,
              index: currentAudioRefIndex.current,
              onTimeUpdatedCallback,
              onEndedCallback,
              onBufferCallback,
              muted: state.muted
            });
        }

        audioRef.volume = state.volume;
        audioRef.muted = state.muted;
        if (audioRef && audioRef.paused) {
          // console.log("PLAY 2 - ", currentAudioRefIndex.current);
          audioRef.play();
        }
        return {
          ...state,
          isPlaying: true,
          isPlayingWithBuffer: true
        };
      }
      case "pause": {
        const audioRef = audioRefs.current[currentAudioRefIndex.current];
        if (!audioRef || audioRef === undefined) {
          return state;
        }

        // console.log("PAUSE 3 - ", currentAudioRefIndex.current);
        audioRef?.pause();
        return {
          ...state,
          isPlaying: false,
          isPlayingWithBuffer: action.payload.buffering ?? false
        };
      }
      case "seekTo": {
        // console.log("SEEKING");
        const { amount, type } = action.payload as {
          amount: number;
          type?: "seconds" | "fraction";
        };
        const fraction = isNaN(amount / state.totalDuration)
          ? 0
          : amount / state.totalDuration;
        const currentTime =
          (type === "fraction" ? fraction * state.totalDuration : amount) ?? 0;

        const audioData = getAudioDataFromTimestamp({
          audioData: state.audioData,
          timestamp: currentTime
        });
        const newCurrentAudioRefIndex = audioData.audioIndex;
        const timestampOfNewCurrentAudio = audioData.audioProgress;

        const oldIndex = currentAudioRefIndex.current;

        if (
          !audioRefs.current[newCurrentAudioRefIndex] ||
          audioRefs.current[newCurrentAudioRefIndex] === undefined
        ) {
          audioRefs.current[newCurrentAudioRefIndex] = generateAudioInstance({
            url: state.audioData[newCurrentAudioRefIndex].url,
            index: newCurrentAudioRefIndex,
            onTimeUpdatedCallback,
            onEndedCallback,
            onBufferCallback,
            muted: state.muted
          });
        }

        audioRefs.current[newCurrentAudioRefIndex].currentTime =
          timestampOfNewCurrentAudio;

        if (newCurrentAudioRefIndex !== oldIndex) {
          // pause the old audio, play the new one
          // console.log("PAUSE 4 - ", oldIndex);
          audioRefs.current[oldIndex]?.pause();
          audioRefs.current[newCurrentAudioRefIndex].volume = state.volume;
          audioRefs.current[newCurrentAudioRefIndex].muted = state.muted;
        }
        if (state.isPlaying && !state.buffering) {
          // console.log("PLAY 3 - ", newCurrentAudioRefIndex);
          audioRefs.current[newCurrentAudioRefIndex].play();
        }

        currentAudioRefIndex.current = newCurrentAudioRefIndex;

        return {
          ...state,
          mediaProgress: currentTime,
          mediaProgressFraction: fraction,
          isPlayingWithBuffer: state.isPlaying
        };
      }
      case "setBuffering": {
        if (state.buffering !== action.payload) {
          // console.log("PROGG BUFFERING");
          return {
            ...state,
            buffering: action.payload
          };
        }
        return state;
      }
      case "setMediaProgress": {
        return {
          ...state,
          mediaProgress: action.payload
        };
      }
      case "setVolume": {
        if (audioRefs.current[currentAudioRefIndex.current].volume) {
          audioRefs.current[currentAudioRefIndex.current].volume =
            action.payload;
        }

        return {
          ...state,
          volume: action.payload
        };
      }
      case "toggleMute": {
        if (audioRefs.current[currentAudioRefIndex.current].muted) {
          audioRefs.current[currentAudioRefIndex.current].muted = !state.muted;
        }

        return {
          ...state,
          muted: !state.muted
        };
      }
      case "setMute": {
        if (audioRefs.current[currentAudioRefIndex.current]) {
          audioRefs.current[currentAudioRefIndex.current].muted =
            action.payload;
        }

        return {
          ...state,
          muted: action.payload
        };
      }
      case "setRenderBuffering": {
        if (state.renderBuffering !== action.payload) {
          // console.log("PROGG RENDER BUFFERING");
          return {
            ...state,
            renderBuffering: action.payload
          };
        }

        return state;
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  }

  const [state, dispatch] = useReducer(
    progressiveMediaPlayerReducer,
    initialState
  );

  const updateCurrentAudioRefIndex = (newIndex: number) => {
    dispatch({
      type: "setCurrentAudioRefIndex",
      payload: newIndex
    });
  };

  const loadAudio = (index: number) => {
    // check if audio instance audioRefs.current[index] is already loading or loaded
    if (
      index > state.audioData.length - 1 ||
      index - 1 !== currentAudioRefIndex.current
    ) {
      return;
    }

    if (
      state.audioData[index] &&
      (!audioRefs.current[index] || audioRefs.current[index] === undefined)
    ) {
      // console.log("GEN 5 - ", index);
      audioRefs.current[index] = generateAudioInstance({
        url: state.audioData[index].url,
        index,
        onTimeUpdatedCallback,
        onEndedCallback,
        onBufferCallback,
        muted: state.muted,
        volume: state.volume
      });
      // console.log("LOAD 2 - ", index);
      audioRefs.current[index].load();
    } else if (
      audioRefs.current[index] &&
      (index !== currentAudioRefIndex.current
        ? true
        : !state.isPlayingWithBuffer && !state.buffering) &&
      audioRefs.current[index].readyState < 3 &&
      audioRefs.current[index].networkState < 2
    ) {
      // console.log(
      //   "LOAD 3 - ",
      //   index,
      //   "NETWORK State - ",
      //   audioRefs.current[index].networkState,
      //   "CurrentAudioRefIndex - ",
      //   currentAudioRefIndex.current
      // );
      audioRefs.current[index].load();
    }
  };

  const updateMediaProgress = (e: Event, index: number) => {
    if (index === currentAudioRefIndex.current) {
      const audioEl = e.target as HTMLAudioElement;

      let previousDurations = 0;
      if (index > 0) {
        for (let i = index - 1; i >= 0; i--) {
          previousDurations += state.audioData[i].duration;
        }
      }
      const newProgress = previousDurations + audioEl.currentTime;

      dispatch({
        type: "setMediaProgress",
        payload: newProgress
      });
    }
  };

  function handleBuffer(_e: Event, index: number, buffering: boolean) {
    if (
      index === currentAudioRefIndex.current
      // &&
      // state.buffering !== buffering
    ) {
      dispatch({
        type: "setBuffering",
        payload: buffering
      });
    }
  }

  if (!ProgressiveAudioPlayerContexts[id]) {
    ProgressiveAudioPlayerContexts[id] =
      createContext<ProgressiveAudioPlayerContextType | null>(null);
  }

  const ProgressivePlayerProviderWithId = ProgressiveAudioPlayerContexts[id];

  const value = useMemo(() => {
    return {
      state,
      dispatch,
      currentPlayingBlockId:
        state.audioData[currentAudioRefIndex.current]?.blockId ?? null,
      currentAudioRef: audioRefs.current[currentAudioRefIndex.current]
    };
  }, [state, dispatch, audioRefs, currentAudioRefIndex]);

  return (
    <ProgressivePlayerProviderWithId.Provider value={value} {...props}>
      {children}
    </ProgressivePlayerProviderWithId.Provider>
  );
}

function useProgressiveAudioPlayer(id: string) {
  if (!ProgressiveAudioPlayerContexts[id]) {
    throw new Error(
      `useProgressiveAudioPlayer must be used within a ProgressiveAudioPlayerProvider`
    );
  }

  const context = useContext(ProgressiveAudioPlayerContexts[id]);
  if (context === undefined) {
    throw new Error(
      `useProgressiveAudioPlayer must be used within a ProgressiveAudioPlayerProvider`
    );
  }

  const audioRef = useRef<HTMLAudioElement | null>();
  audioRef.current = context?.currentAudioRef;

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  return context;
}

function setupProgressivePlayer({
  dispatch,
  payload
}: {
  dispatch: Dispatch<ProgressiveAudioPlayerActionType>;
  payload: {
    audioData: ProgressiveAudioDataType[];
    totalDuration: number;
  };
}) {
  dispatch({
    type: "setup",
    payload
  });
}

function setProgressivePlayerAudioData({
  dispatch,
  payload
}: {
  dispatch: Dispatch<ProgressiveAudioPlayerActionType>;
  payload: {
    audioData: ProgressiveAudioDataType[];
  };
}) {
  dispatch({
    type: "updateAudioData",
    payload
  });
}

function playProgressivePlayer({
  dispatch
}: {
  dispatch: Dispatch<ProgressiveAudioPlayerActionType>;
}) {
  dispatch({
    type: "play"
  });
}

function pauseProgressivePlayer({
  dispatch,
  payload
}: {
  dispatch: Dispatch<ProgressiveAudioPlayerActionType>;
  payload: { buffering?: boolean };
}) {
  dispatch({
    type: "pause",
    payload
  });
}

function seekProgressivePlayer({
  dispatch,
  payload
}: {
  dispatch: Dispatch<ProgressiveAudioPlayerActionType>;
  payload: MediaPlayerSeekToParams;
}) {
  dispatch({
    type: "seekTo",
    payload
  });
}

function setProgressivePlayerMuted(
  dispatch: Dispatch<ProgressiveAudioPlayerActionType>,
  payload: boolean
) {
  dispatch({
    type: "setMute",
    payload
  });
}

function setProgressivePlayerVolume(
  dispatch: Dispatch<ProgressiveAudioPlayerActionType>,
  payload: number
) {
  dispatch({
    type: "setVolume",
    payload
  });
}

function setProgressivePlayerRenderBuffering({
  dispatch,
  payload
}: {
  dispatch: Dispatch<ProgressiveAudioPlayerActionType>;
  payload: boolean;
}) {
  dispatch({
    type: "setRenderBuffering",
    payload
  });
}

export {
  ProgressiveAudioPlayerProvider,
  useProgressiveAudioPlayer,
  setupProgressivePlayer,
  setProgressivePlayerAudioData,
  playProgressivePlayer,
  pauseProgressivePlayer,
  seekProgressivePlayer,
  setProgressivePlayerMuted,
  setProgressivePlayerVolume,
  setProgressivePlayerRenderBuffering
};
