import { Box } from "@mui/material";
import React from "react";
import Player from "./components/Player";
import { DubMediaPlayerProps } from "./types";

const DubMediaPlayer = ({
  type = "video",
  mediaSrc,
  playerProps,
  mediaPlayerId,
  seekerMarks,
  boxStyleProps,
  mediaPlayerProps,
  playerWrapperStyles,
  outerWrapperStyles,
  thumbnailSrc
}: DubMediaPlayerProps): React.ReactNode => {
  return (
    <Box
      sx={{
        borderRadius: 2,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: "center",
        ...outerWrapperStyles
      }}
    >
      <Player
        {...{
          type,
          mediaSrc,
          playerProps,
          mediaPlayerId,
          boxStyleProps,
          mediaPlayerProps,
          seekerMarks,
          playerWrapperStyles,
          thumbnailSrc
        }}
      />
    </Box>
  );
};

export default DubMediaPlayer;
