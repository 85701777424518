import { LANGUAGES, LanguageDataMap } from "@/constants/languages";
import { getLanguageFlag } from "@/utils/links";
import { Theme } from "@emotion/react";
import {
  Avatar,
  Chip,
  ChipOwnProps,
  ChipProps,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
  SxProps,
  Typography,
  TypographyOwnProps
} from "@mui/material";
import { UserAvatar } from "..";

interface LanguagePillProps {
  language: LANGUAGES | string;
  size?: "default" | "small";
  variant?: "minimal" | "filled";
  showMeta?: boolean;
  metaText?: string;
  sx?: SxProps<Theme>;
  chipProps?: ChipProps;
  listItemProps?: ListItemProps;
  labelVariant?: TypographyOwnProps["variant"];
}

const LanguagePill = ({
  language,
  size = "default",
  variant = "minimal",
  showMeta = false,
  metaText,
  sx,
  chipProps,
  listItemProps,
  labelVariant
}: LanguagePillProps) => {
  const getVariant = (): TypographyOwnProps["variant"] => {
    if (labelVariant) return labelVariant;
    if (size === "default") return "caption";

    return "caption";
  };

  const getSize = (): ChipOwnProps["size"] => {
    if (size === "default") return "medium";

    return "small";
  };

  const getBackground = (): string | undefined => {
    if (variant === "minimal") {
      return "transparent";
    }

    if (variant === "filled" && showMeta) {
      return "elevation.outlinedBorder";
    }

    return undefined;
  };

  if (showMeta) {
    return (
      <ListItem
        id="lock-button"
        aria-haspopup="listbox"
        sx={{
          p: (theme) => (variant === "minimal" ? 0 : theme.spacing(0.25, 1)),
          bgcolor: getBackground(),
          borderRadius: 56
        }}
        {...listItemProps}
      >
        <ListItemAvatar
          sx={{
            height: 32,
            width: 32,
            minWidth: 32,
            mr: (theme) => theme.spacing(1)
          }}
        >
          <UserAvatar
            displayName={language.toLowerCase()}
            avatarUrl={getLanguageFlag({
              language:
                LanguageDataMap[language as LANGUAGES]?.flag ?? "globe.svg"
            })}
            alt=""
            sizes="32"
            sx={{
              height: 32,
              width: 32
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="subtitle2" textTransform={"capitalize"}>
              {LanguageDataMap[language as LANGUAGES]?.name}
            </Typography>
          }
          secondary={
            <Typography variant="caption" color={"text.secondary"}>
              {metaText || LanguageDataMap[language as LANGUAGES]?.name}
            </Typography>
          }
          sx={{
            my: 0,
            display: "flex",
            flexDirection: "column",
            pr: variant === "minimal" ? 0 : 1
          }}
        />
      </ListItem>
    );
  }

  return (
    <Chip
      avatar={
        <Avatar
          alt="Language"
          src={getLanguageFlag({
            language:
              LanguageDataMap[language as LANGUAGES]?.flag ?? "globe.svg"
          })}
        />
      }
      size={getSize()}
      label={
        <Typography variant={getVariant()}>
          {LanguageDataMap[language as LANGUAGES]?.name ?? ""}
        </Typography>
      }
      sx={[
        {
          background: getBackground()
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...chipProps}
    />
  );
};

export default LanguagePill;
