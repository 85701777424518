import { IIssue } from "@/features/comments";

const MIN_DIVISION = 1;

export const getMarksForIssues = ({
  allIssues,
  duration = 0,
  seekerId
}: {
  allIssues: IIssue[];
  duration: number;
  seekerId: string;
}) => {
  if (!allIssues) {
    return [];
  }

  let marks: any = [];
  const groupedMarks: any = {};

  let division = MIN_DIVISION;

  const seekerWidth = document.getElementById(seekerId)?.clientWidth || 0;

  if (seekerWidth / 100 < 6) {
    division = 16;
  }

  marks = duration
    ? allIssues.map((issue) => ({
        value: Math.floor((issue.startTimeStamp / duration) * 100),
        label: issue.issueId
      }))
    : [];

  marks.forEach((mark: any) => {
    const group = Math.ceil(mark.value / division);
    const _temp = marks.filter(
      (m: any) => Math.ceil(m.value / division) === group
    );
    const key =
      _temp.length === 1
        ? _temp[0].value
        : _temp.sort((a: any, b: any) => a.value - b.value)[
            Math.floor(_temp.length / 2)
          ].value;

    groupedMarks[key] = _temp.map((m: any) => m.label);
  });

  return Object.keys(groupedMarks).map((key) => [key, groupedMarks[key]]);
};
