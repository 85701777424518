export type ExportType = "VIDEO" | "AUDIO" | "SUBTITLES";

export const fileFormats = {
  VIDEO: ["MP4", "MKV", "AVI", "MOV"],
  AUDIO: ["MP3", "WAV", "AAC"],
  SUBTITLES: ["SRT", "VTT"]
};

type Option = {
  value: string;
  title: string;
  description?: string;
};

type Options = {
  values: Option[];
  label: string;
  selectedValue: string;
};

type Checkbox = {
  value: string;
  label: string;
  checked: boolean;
  settings?: Settings;
  canShow?: (obj: { [key: string]: any }) => boolean;
};

type Settings = {
  checkbox?: Checkbox[];
  options?: Options;
};

export type LocalExportConfig = {
  value: ExportType;
  title: string;
  formats: Option[];
  selectedFormat: string;
  settings?: Settings;
};

export const exportConfigData: Record<ExportType, LocalExportConfig> = {
  VIDEO: {
    value: "VIDEO",
    title: "Video",
    formats: fileFormats.VIDEO.map((format) => ({
      value: format,
      title: format
    })),
    selectedFormat: "MP4",
    settings: {
      checkbox: [
        {
          value: "prores",
          label: "Use ProRes codec",
          checked: false,
          canShow: ({ config }) => config?.selectedFormat === "MOV"
        },
        {
          value: "burnSubtitles",
          label: "Burn subtitles in the video",
          checked: false,
          canShow: ({ exportLevel } = {}) => exportLevel === "DUB"
        },
        {
          value: "downloadSubtitles",
          label: "Download subtitles",
          checked: false,
          settings: {
            options: {
              label: "Format",
              selectedValue: "SRT",
              values: fileFormats.SUBTITLES.map((format) => ({
                value: format,
                title: format
              }))
            },
            checkbox: [
              {
                value: "includePauses",
                label: "Include pauses in subtitles",
                checked: false
              }
            ]
          }
        }
      ]
    }
  },
  AUDIO: {
    value: "AUDIO",
    title: "Audio",

    formats: fileFormats.AUDIO.map((format) => ({
      value: format,
      title: format
    })),
    selectedFormat: "MP3",
    settings: {
      options: {
        label: "Quality",
        selectedValue: "_48K",
        values: [
          {
            value: "_8K",
            title: "Low",
            description: "(8KHz, 16bit)"
          },
          {
            value: "_24K",
            title: "Medium",
            description: "(24KHz, 16bit)"
          },
          {
            value: "_48K",
            title: "High",
            description: "(48KHz, 16bit)"
          }
        ]
      }
    }
  },
  SUBTITLES: {
    value: "SUBTITLES",
    title: "Subtitles",
    formats: fileFormats.SUBTITLES.map((format) => ({
      value: format,
      title: format
    })),
    selectedFormat: "SRT",
    settings: {
      checkbox: [
        {
          value: "includePauses",
          label: "Include pauses in subtitles",
          checked: false
        }
      ]
    }
  }
};
