export enum STEPS {
  CONTACT_SALES,
  SUCCESS
}

export const RATES = {
  PRE_PROCESSING: 0,
  FIXED_DUB_COST: 2,
  QC_COST: 20,
  LIP_SYNC_COST: 5,
  FACE_MARKING: 5
};

export enum CREDIT_EVENT_TYPE {
  BOOK_DEMO = "BookDemo",
  BUY_CREDITS = "BuyCredits",
  CREDITS_EXHAUSTED = "CreditsExhausted",
  PLAN_EXPIRED = "PlanExpired",
  PLAN_UPGRADE = "PlanUpgrade"
}
