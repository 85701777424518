import { IClientProjectHomeData } from "@/features/clientHome";
import { clientProjectThunk } from "@/reducers/thunks/clientProject";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IAllProjectsData {
  [key: string]: IClientProjectHomeData;
}
interface IClientHomeSlice {
  allProjects: IAllProjectsData;
}

const clientHomeSlice = createSlice({
  name: "clientHomeSlice",
  initialState: {
    allProjects: {}
  } as IClientHomeSlice,
  reducers: {
    setInitialHomeState: (
      state,
      action: PayloadAction<IClientProjectHomeData[]>
    ) => {
      const data: IClientHomeSlice["allProjects"] = {};
      action.payload?.map((project) => {
        data[project.projectId!] = project;
      });
      state.allProjects = data;
    },
    updateProjectData: (
      state,
      action: PayloadAction<{
        projectId: string;
        newData: Partial<IClientProjectHomeData>;
      }>
    ) => {
      const { projectId, newData } = action.payload;
      if (projectId && state.allProjects[projectId]) {
        state.allProjects[projectId] = {
          ...state.allProjects[projectId],
          ...newData
        } as IClientProjectHomeData;
      }
    },
    addProjectOnHome: (
      state,
      action: PayloadAction<{
        projectData: IClientProjectHomeData;
      }>
    ) => {
      const { projectData } = action.payload;
      if (!projectData.projectId) {
        return;
      }
      if (state.allProjects[projectData.projectId]) {
        return;
      }

      state.allProjects[projectData.projectId] = projectData;
    },
    updateProjectOnHome: (
      state,
      action: PayloadAction<{
        projectData: IClientProjectHomeData;
      }>
    ) => {
      const { projectData } = action.payload;
      if (!projectData.projectId) {
        return;
      }
      if (state.allProjects[projectData.projectId]) {
        state.allProjects[projectData.projectId] = {
          ...state.allProjects[projectData.projectId],
          ...projectData
        };
      }
    },
    removeProjectFromHome: (
      state,
      action: PayloadAction<{ projectId: string }>
    ) => {
      const { projectId } = action.payload;
      if (state.allProjects[projectId]) {
        delete state.allProjects[projectId];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(clientProjectThunk.updateProject.fulfilled, (state, action) => {
        const project = action.payload?.data.responseData;
        if (state.allProjects[project.projectId]) {
          state.allProjects[project.projectId] = {
            ...state.allProjects[project.projectId],
            ...project
          };
        }
      })
      .addCase(clientProjectThunk.createProject.fulfilled, (state, action) => {
        const project = action.payload?.data.responseData;
        if (state.allProjects[project.projectId]) {
          return;
        }

        state.allProjects[project.projectId] = project;
      })
      .addCase(
        clientProjectThunk.duplicateProject.fulfilled,
        (state, action) => {
          const project = action.payload?.data.responseData.project;
          if (state.allProjects[project.projectId]) {
            return;
          }

          state.allProjects[project.projectId] = project;
        }
      );
  }
});

export const {
  setInitialHomeState,
  updateProjectData,
  addProjectOnHome,
  updateProjectOnHome,
  removeProjectFromHome
} = clientHomeSlice.actions;

export default clientHomeSlice.reducer;
