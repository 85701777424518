import clientProjectPopupSlice from "./slices/clientProjectPopupSlice/clientProjectPopupSlice";
import currentScriptProjectReducer from "./slices/currentScriptProjectSlice/currentScriptProjectSlice";
import dialogReducer from "./slices/dialogSlice/dialogSlice";
import projectUtilitySliceReducer from "./slices/projectUtilitySlice/projectUtilitySlice";
import userSliceReducer from "./slices/userSlice/userSlice";
import workspaceReducer from "./slices/workspaceSlice/workspaceSlice";
import dubPreviewReducer from "./slices/dubPreviewSlice/dubPreviewSlice";
import tuningReducer from "./slices/tuningSlice/tuningSlice";
import internalProjectReducer from "./slices/internalProjectSlice/internalProjectSlice";
import uppySlice from "./slices/uppySlice/uppySlice";
import currentClientProjectSlice from "./slices/currentClientProjectSlice/currentClientProjectSlice";
import clientHomeSlice from "./slices/clientHomeSlice/clientHomeSlice";
import issueSlice from "./slices/issueSlice/issueSlice";
import commentsSlice from "./slices/commentsSlice/commentsSlice";
import authReducer from "./slices/authSlice";
import globalReducer from "./slices/globalSlice";
import userManagementReducer from "./slices/userManagementSlice/userManagementSlice";
import pricingDialogReducer from "./slices/pricingDialogSlice/pricingDialogSlice";

const reducer = {
  currentScriptProject: currentScriptProjectReducer,
  projectUtility: projectUtilitySliceReducer,
  dialog: dialogReducer,
  user: userSliceReducer,
  workspace: workspaceReducer,
  dubPreview: dubPreviewReducer,
  internalProject: internalProjectReducer,
  tuning: tuningReducer,
  clientProjectPopup: clientProjectPopupSlice,
  uppyHandler: uppySlice,
  currentClientProject: currentClientProjectSlice,
  clientHome: clientHomeSlice,
  issues: issueSlice,
  comments: commentsSlice,
  auth: authReducer,
  globalState: globalReducer,
  userManagement: userManagementReducer,
  pricingDialog: pricingDialogReducer
};

export default reducer;
