import { Box, CircularProgress, Stack } from "@mui/material";
import { updateDubDetails } from "@/reducers/slices/dubPreviewSlice/dubPreviewSlice";
import { useAppDispatch } from "@/config/configureAppStore";
import { useCallback, useEffect, useState } from "react";
import unauthenticatedApi from "../api";
import { STATUS } from "@/constants/status";
import { useParams } from "react-router-dom";
import Preview from "../components/SharedDub/Preview";
import SharePassword from "../components/SharedDub/SharePassword";
import ShareDubLayout from "../components/SharedDub/ShareDubLayout";
import { SERVER_RESPONSES } from "@/constants/serverConstants";
import { setSnackBar } from "@/reducers/slices/dialogSlice/dialogSlice";
import { UNKNOWN_ERROR_MESSAGE } from "@/constants/errors";

const SharedDub = () => {
  const [apiStatus, setApiStatus] = useState(STATUS.IDLE);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const dispatch = useAppDispatch();
  const { shareId } = useParams();

  const fetchSharedMedia = useCallback(
    (password?: string) => {
      if (shareId) {
        setApiStatus(STATUS.LOADING);
        unauthenticatedApi
          .fetchSharedMedia({ shareId, password })
          .then((response) => {
            if (response.data) {
              setApiStatus(STATUS.SUCCESS);
              setPasswordRequired(false);
              const data = response.data.responseData;
              dispatch(
                updateDubDetails({
                  fileName: data.fileName,
                  sourceLocale: data.sourceLocale,
                  language: data.targetLocale,
                  previewUrl: data.previewUrl,
                  originalAudioUrl: data.originalAudioUrl,
                  dubbedVttUrl: data.dubbedVttUrl,
                  transcriptVttUrl: data.transcriptVttUrl,
                  audioOnly: data.audioOnly,
                  thumbnailUrl: data.thumbnailUrl
                })
              );
            } else {
              setApiStatus(STATUS.ERROR);
            }
          })
          .catch((e) => {
            if (e?.code === SERVER_RESPONSES.ACCESS_DENIED) {
              setPasswordRequired(true);
              if (password)
                dispatch(
                  setSnackBar({
                    message: "Invalid password",
                    type: "error",
                    open: true
                  })
                );
              setApiStatus(STATUS.IDLE);
              return;
            } else {
              setApiStatus(STATUS.ERROR);
              dispatch(
                setSnackBar({
                  message: e?.extra || e?.message || UNKNOWN_ERROR_MESSAGE,
                  type: "error",
                  open: true
                })
              );
            }
          });
      }
    },
    [dispatch, shareId]
  );

  useEffect(() => {
    if (!passwordRequired && apiStatus === STATUS.IDLE) {
      fetchSharedMedia();
    }
  }, [apiStatus, fetchSharedMedia, passwordRequired]);

  const openPasswordLink = (password: string) => {
    fetchSharedMedia(password);
  };

  return (
    <ShareDubLayout>
      <Box
        display="flex"
        sx={{
          height: "100%"
        }}
      >
        <Stack
          sx={{
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {apiStatus === STATUS.LOADING ? (
            <CircularProgress />
          ) : passwordRequired ? (
            <SharePassword openPasswordLink={openPasswordLink} />
          ) : null}
          {apiStatus === STATUS.SUCCESS ? <Preview /> : null}
        </Stack>
        {/* <Sidebar /> */}
      </Box>
    </ShareDubLayout>
  );
};

export default SharedDub;
