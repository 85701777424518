import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  styled
} from "@mui/material";
import React from "react";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 8,
    backgroundColor: theme.palette.background.default,
    border: "none",
    backgroundImage: "none"
  }
}));

interface ActionProps {
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export interface DubDialogProps {
  onClose: () => void;
  footerProps?: {
    primaryAction?: ActionProps;
    secondaryActions?: ActionProps;
    style?: DialogActionsProps["sx"];
  };
  headerProps?: {
    preIcon?: React.ReactNode;
    title: string;
    showCloseIcon?: boolean;
  };
  callOut?: {
    icon?: React.ReactNode;
    title: string | React.ReactNode[] | React.ReactNode;
  };
  children?: React.ReactNode;
  dialogProps?: DialogProps;
}

export const DubDialog = ({
  onClose,
  footerProps,
  headerProps,
  callOut,
  children,
  dialogProps
}: DubDialogProps): React.ReactNode => {
  return (
    <StyledDialog
      open={true}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      {...dialogProps}
      closeAfterTransition
    >
      {headerProps ? (
        <DialogTitle
          sx={{
            paddingX: 3,
            paddingY: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Box display="flex" gap={1} alignItems={"center"}>
            {headerProps?.preIcon}
            <Typography variant="h6">{headerProps?.title}</Typography>
          </Box>
          {headerProps?.showCloseIcon ? (
            <IconButton size="small" onClick={onClose}>
              <CloseRounded />
            </IconButton>
          ) : null}
        </DialogTitle>
      ) : null}
      <DialogContent
        sx={{
          padding: 4,
          paddingBottom: 2
        }}
      >
        <Box
          sx={{
            paddingTop: 4,
            gap: 3,
            display: "flex",
            flexDirection: "column"
          }}
        >
          {callOut ? (
            <Stack
              bgcolor="secondary.hover"
              alignItems="center"
              textAlign="center"
              padding={3}
              gap={2}
              borderRadius={3}
            >
              {callOut.icon}
              {callOut?.title ? (
                <Typography variant="h6" color="text.primary">
                  {callOut.title}
                </Typography>
              ) : null}
            </Stack>
          ) : null}
          {children}
        </Box>
      </DialogContent>
      <DialogActions
        disableSpacing
        sx={{ padding: 4, gap: 2, ...footerProps?.style }}
      >
        {footerProps?.secondaryActions ? (
          <Button
            variant="outlined"
            color="secondary"
            disabled={
              footerProps.secondaryActions.isDisabled ||
              footerProps.secondaryActions.isLoading
            }
            onClick={footerProps.secondaryActions.onClick}
            startIcon={
              footerProps.secondaryActions.isLoading ? (
                <CircularProgress size={16} />
              ) : null
            }
          >
            {footerProps.secondaryActions.label}
          </Button>
        ) : null}
        {footerProps?.primaryAction ? (
          <Button
            variant="contained"
            color="primary"
            onClick={footerProps.primaryAction.onClick}
            disabled={
              footerProps.primaryAction.isDisabled ||
              footerProps.primaryAction.isLoading
            }
            startIcon={
              footerProps.primaryAction.isLoading ? (
                <CircularProgress size={18} color="primary" />
              ) : null
            }
          >
            {footerProps.primaryAction.label}
          </Button>
        ) : null}
      </DialogActions>
    </StyledDialog>
  );
};
