import LanguagePill from "@/components/elements/LanguagePill";
import MurfAppBar from "@/components/layout/MurfAppBar";
import { useTypedSelector } from "@/config/configureAppStore";
import { Typography } from "@mui/material";

const ShareTopBar = () => {
  const fileName = useTypedSelector(
    (state) => state.dubPreview.dubDetails.fileName
  );
  const dubLanguage = useTypedSelector(
    (state) => state.dubPreview.dubDetails.language
  );
  return (
    <MurfAppBar gap={1}>
      {fileName ? (
        <>
          <Typography
            color={"text.primary"}
            variant="subtitle2"
            maxWidth={500}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            whiteSpace={"nowrap"}
          >
            {fileName}
          </Typography>
          <LanguagePill variant="filled" size="small" language={dubLanguage} />
        </>
      ) : null}
    </MurfAppBar>
  );
};

export default ShareTopBar;
