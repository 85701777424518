import { useCallback, useMemo } from "react";
import { BusinessDetails, PLANS_CATEGORY } from "../types";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { trackMixpanelEvent } from "@/utils/mixpanel";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { pricingDialogSlice } from "@/reducers/slices/pricingDialogSlice/pricingDialogSlice";
import { STATUS } from "@/constants/status";
import { pricingThunks } from "@/reducers/thunks/pricingThunks";

let abortController: AbortController | null = null;

export const usePricing = () => {
  const dispatch = useAppDispatch();
  const { businessDetails, checkoutPlan, topupValue } = useTypedSelector(
    (state) => state.pricingDialog.checkoutDetails
  );
  const { entryPoint } = useTypedSelector((state) => state.pricingDialog);
  const { useStripeIndia } = useTypedSelector(
    (state) => state.workspace.workspacePlan || {}
  );

  const businessDetailsComplete = useMemo(
    () =>
      !!(
        businessDetails.businessName.trim().length &&
        businessDetails.taxId.trim().length &&
        businessDetails.taxType.trim().length
      ),
    [businessDetails]
  );

  const isBusinessDetailsComplete = useCallback(
    ({ businessName, taxId, taxType }: BusinessDetails) => {
      return !!(
        businessName.trim().length &&
        taxId.trim().length &&
        taxType.trim().length
      );
    },
    []
  );

  const cancelUpgradePreview = useCallback(
    ({ resetStatus = false } = {}) => {
      if (resetStatus) {
        dispatch(pricingDialogSlice.actions.setPreviewStatus(STATUS.IDLE));
      }
      abortController?.abort();
    },
    [dispatch]
  );

  const callUpgradePreview = useCallback(() => {
    cancelUpgradePreview();
    abortController = new AbortController();
    dispatch(pricingThunks.fetchPricingPreview({ abortController }))
      .unwrap()
      .then(() => {
        trackMixpanelEvent(MIXPANEL_EVENTS.UPGRADE_START_SUMMARY, {
          "New Plan": checkoutPlan?.category,
          "New Plan Type":
            checkoutPlan?.category === PLANS_CATEGORY.FREE ? "Free" : "Paid",
          "New Plan Value": topupValue,
          "Stripe Account Type": useStripeIndia ? "India" : "US",
          "Promocode Applied": "False",
          "Entry Type": entryPoint
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelUpgradePreview, dispatch]);

  return {
    callUpgradePreview,
    cancelUpgradePreview,
    businessDetailsComplete,
    isBusinessDetailsComplete
  };
};
