import { Stack } from "@mui/material";
import ShareTopBar from "./ShareTopBar";
import { ReactNode } from "react";

const ShareDubLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Stack height="100vh">
      <ShareTopBar />
      {children}
    </Stack>
  );
};

export default ShareDubLayout;
