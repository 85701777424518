import { useTypedSelector } from "@/config/configureAppStore";
import { CircularProgress, Stack } from "@mui/material";
import React, { Suspense } from "react";
import { PRICING_DIALOG_STEP } from "../types";
const PricingPlans = React.lazy(() => import("./PricingPlans"));
// const SetupFunds = React.lazy(() => import("./SetupFunds"));
const CalculateCredit = React.lazy(() => import("./CalculateCredit"));
// const CheckoutForm = React.lazy(() => import("./CheckoutForm"));
// const PaymentSuccess = React.lazy(() => import("./PaymentSuccess"));

const PricingDialogContent = (): React.ReactNode => {
  const { activeStep, calculateCreditDialogOpen } = useTypedSelector(
    (state) => state.pricingDialog
  );

  if (calculateCreditDialogOpen) {
    return (
      <Suspense
        fallback={
          <Stack
            height={"100%"}
            my={3}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Stack>
        }
      >
        <CalculateCredit />
      </Suspense>
    );
  }

  return (
    <Suspense
      fallback={
        <Stack
          height={"100%"}
          minHeight={600}
          my={3}
          alignItems="center"
          justifyContent="center"
          p={5}
        >
          <CircularProgress />
        </Stack>
      }
    >
      {activeStep === PRICING_DIALOG_STEP.PRICING_PLANS && <PricingPlans />}
      {activeStep === PRICING_DIALOG_STEP.SETUP_FUNDS && null}
      {activeStep === PRICING_DIALOG_STEP.CHECKOUT && null}
      {activeStep === PRICING_DIALOG_STEP.PAYMENT_SUCCESS && null}
      {/* {activeStep === PRICING_DIALOG_STEP.SETUP_FUNDS && <SetupFunds />}
      {activeStep === PRICING_DIALOG_STEP.CHECKOUT && <CheckoutForm />}
      {activeStep === PRICING_DIALOG_STEP.PAYMENT_SUCCESS && <PaymentSuccess />} */}
    </Suspense>
  );
};

export default PricingDialogContent;
