import {
  MediaPlayer,
  MediaPlayerProps,
  setMute,
  setVolume,
  useMediaPlayer,
  useMediaPlayerManager
} from "@/features/mediaPlayer";
import {
  DUB_PREVIEW_MAIN_PLAYER_ID,
  DUB_PREVIEW_ORIGINAL_PLAYER_ID
} from "../../constants/config";
import { Box } from "@mui/material";
import { useMemo, useEffect } from "react";
import { useTypedSelector, useAppDispatch } from "@/config/configureAppStore";
import {
  PreviewPlayer,
  updateActiveBlockId
} from "@/reducers/slices/dubPreviewSlice/dubPreviewSlice";
import DubMediaPlayer from "@/components/elements/DubMediaPlayer";
import { useParams } from "react-router-dom";
import { getMarksForIssues } from "./utils";
import { DubPlayerCommentGroup } from "./components/DubPlayerCommentGroup";
import { MEDIA_TYPE_EXTN } from "@/features/clientSearch/constant";
import { useIssuesComments } from "@/features/comments/hooks";

export function PreviewMediaPlayer({
  mediaPlayerProps,
  updateMarks
}: {
  mediaPlayerProps?: Partial<MediaPlayerProps>;
  updateMarks?: number; // @TODO: change the name to something meaningful
}) {
  const dubbedMediaContext = useMediaPlayer(DUB_PREVIEW_MAIN_PLAYER_ID)!;
  const originalMediaContext = useMediaPlayer(DUB_PREVIEW_ORIGINAL_PLAYER_ID)!;
  const { playAll, pauseAll } = useMediaPlayerManager({
    players: [dubbedMediaContext, originalMediaContext],
    primaryPlayerIndex: 0
  });
  const dispatch = useAppDispatch();
  const dubPreviewBlocks = useTypedSelector(
    (state) => state.dubPreview.dubDetails.blocks
  );
  const activeBlockId = useTypedSelector(
    (state) => state.dubPreview.activeBlockId
  );
  const dubPreviewMediaUrl = useTypedSelector(
    (state) => state.dubPreview.dubDetails.previewUrl
  );
  const originalPreviewMediaUrl = useTypedSelector(
    (state) => state.dubPreview.dubDetails.originalAudioUrl
  );
  const currentPlayer = useTypedSelector(
    (state) => state.dubPreview.currentPlayer
  );
  const audioOnly = useTypedSelector(
    (state) => state.dubPreview.dubDetails.audioOnly
  );

  const { dubId } = useParams();

  const { filteredIssues: allIssues } = useIssuesComments();

  const marks = useMemo(() => {
    if (!allIssues) {
      return [];
    }
    const duration =
      dubbedMediaContext.mediaPlayerRef.current?.getDuration() ?? 0;
    const _marks = getMarksForIssues({
      allIssues,
      duration,
      seekerId: `${DUB_PREVIEW_MAIN_PLAYER_ID}-seeker`
    });
    return _marks.map(([value, groupedIssues]) => ({
      value: Number(value),
      label: (
        <DubPlayerCommentGroup dubId={dubId!} groupedIssues={groupedIssues} />
      )
    }));
    // updateMarks is being used as a dependency to update the marks position on video size change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIssues, dubbedMediaContext, dubId, updateMarks]);

  const currentBlock = useMemo(() => {
    return (
      dubPreviewBlocks?.find(
        (block) =>
          block.original.startTime! <= dubbedMediaContext.state.mediaProgress &&
          block.original.endTime! >= dubbedMediaContext.state.mediaProgress
      )?.blockId ?? null
    );
  }, [dubPreviewBlocks, dubbedMediaContext.state.mediaProgress]);

  useEffect(() => {
    if (typeof dubbedMediaContext.state.volume === "number")
      setVolume(originalMediaContext.dispatch, dubbedMediaContext.state.volume);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dubbedMediaContext.state.volume]);

  useEffect(() => {
    // on player changes
    if (currentPlayer === PreviewPlayer.Original) {
      // if current player is original un-muting original and muting dubbed
      setMute(originalMediaContext.dispatch, false);
      setMute(dubbedMediaContext.dispatch, true);
    } else if (currentPlayer === PreviewPlayer.Dubbed) {
      // if current player is dubbed then muting original and un-muting dubbed
      setMute(originalMediaContext.dispatch, true);
      setMute(dubbedMediaContext.dispatch, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayer]);

  useEffect(() => {
    if (
      !dubbedMediaContext.state?.isPlaying &&
      !dubbedMediaContext.state.mediaProgress
    ) {
      return;
    }
    if (currentBlock !== activeBlockId) {
      const parent = document; //document.getElementById("dub-preview-wrapper") || document;
      parent
        .querySelector(`[data-dubpreview-blockid="${currentBlock}"]`)
        ?.scrollIntoView({
          behavior: "smooth"
          // block: "center"
        });
      dispatch(updateActiveBlockId(currentBlock));
    }
  }, [currentBlock, activeBlockId, dispatch, dubbedMediaContext.state]);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          borderRadius: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%"
        }}
      >
        <DubMediaPlayer
          mediaSrc={dubPreviewMediaUrl}
          mediaPlayerId={DUB_PREVIEW_MAIN_PLAYER_ID}
          type={audioOnly ? MEDIA_TYPE_EXTN.AUDIO : MEDIA_TYPE_EXTN.VIDEO}
          boxStyleProps={{
            height: "auto",
            bgcolor: "black",
            borderRadius: 3,
            overflow: "hidden",
            maxHeight: "100%"
          }}
          seekerMarks={marks}
          mediaPlayerProps={{
            ...mediaPlayerProps,
            onPlay: () => {
              playAll();
            },
            onPause: () => {
              pauseAll();
            },
            enableCustomFullScreenControls: true
          }}
          outerWrapperStyles={{
            height: "100%"
          }}
        />
        <MediaPlayer
          id={DUB_PREVIEW_ORIGINAL_PLAYER_ID}
          url={originalPreviewMediaUrl}
          type={MEDIA_TYPE_EXTN.AUDIO}
          hidden
        />
      </Box>
    </>
  );
}
