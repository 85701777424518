import { api } from "@/lib/axios";
import { APIResponse } from "@/types/apiResponse";
import { TMember } from "..";
import { GenericAbortSignal } from "axios";
import { TASK_TYPE } from "@/types/project";
import { LANGUAGES } from "@/constants/languages";

export const userManagementApi = {
  fetchInternalUsers: (signal?: GenericAbortSignal) =>
    api.get<APIResponse<TMember[]>>("/admin/user/internal-users", {
      signal
    }),

  addUser: (data: any) => api.put<APIResponse>("admin/user/create", data),
  updateSpecialisation: (userId: string, specialisation: TASK_TYPE[]) =>
    api.put<APIResponse>(`admin/user/update-specialisation?userId=${userId}`, {
      specialisation
    }),
  updateLanguage: (userId: string, languages: LANGUAGES[]) =>
    api.put<APIResponse>(`admin/user/update-language?userId=${userId}`, {
      languages
    }),
  updateFinanceAdmin: (userId: string, financeAccess: boolean) =>
    api.put<APIResponse>(
      `admin/user/update-finance-admin/${userId}/${financeAccess}`,
      {
        financeAccess
      }
    )
};
