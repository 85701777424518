import { ProgressiveAudioDataType } from "../..";

export function getAudioDataFromTimestamp({
  audioData,
  timestamp
}: {
  audioData: ProgressiveAudioDataType[];
  timestamp: number;
}) {
  let audioIndex = 0;
  let audioProgress = 0;
  let tempDuration = 0;
  for (let i = 0; i < audioData.length; i++) {
    tempDuration += audioData[i].duration;
    audioIndex = i;
    if (tempDuration >= timestamp) {
      audioProgress = audioData[i].duration - (tempDuration - timestamp);
      break;
    }
  }
  return { audioIndex, audioProgress };
}

export function generateAudioInstance({
  url,
  index,
  onTimeUpdatedCallback,
  onEndedCallback,
  onBufferCallback,
  muted,
  currentTime,
  volume
}: {
  url: string;
  index: number;
  onTimeUpdatedCallback: (event: Event, index: number) => void;
  onEndedCallback: (event: Event, index: number) => void;
  onBufferCallback: (event: Event, index: number, buffering: boolean) => void;
  muted: boolean;
  currentTime?: number;
  volume?: number;
}) {
  const audio = new Audio(url);

  audio.muted = muted;

  audio.currentTime = currentTime ?? 0;
  audio.volume = volume ?? 1;

  audio.onplay = (e) => {
    // const audioElement = e.target as HTMLAudioElement;
    if (!isBufferedForFuture()) {
      // console.log("ON PLAY BUFFER - ", index);
      onBufferCallback(e, index, true);
    }
    // if (audioElement.readyState < 4 && audioElement.networkState === 2) {
    //   console.log("ON PLAY BUFFER - ", audioElement.readyState);
    //   onBufferCallback(e, index, true);
    // }
  };

  audio.ontimeupdate = (e) => {
    // const audioElement = e.target as HTMLAudioElement;
    if (!isBufferedForFuture()) {
      // console.log("ON TIME BUFFER - ", index);
      onBufferCallback(e, index, true);
    } else {
      // console.log("BUFFERING ENDED 3 - ", index);
      onBufferCallback(e, index, false);
    }
    // if (audioElement.readyState < 4 && audioElement.networkState === 2) {
    //   console.log("ON TIME BUFFER - ", index);
    //   onBufferCallback(e, index, true);
    // }
    onTimeUpdatedCallback(e, index);
  };

  audio.onended = (e) => onEndedCallback(e, index);

  // using this was causing the audio to jitter at the start of play
  // audio.onwaiting = (e) => {
  //   console.log("ONWAITING", index, audio.readyState);
  //    if (audio.readyState < 3 && audio.networkState === 2) {
  //   onBufferCallback(e, index, true);
  // }
  // };

  audio.onprogress = (e) => {
    // const audioElement = e.target as HTMLAudioElement;
    if (!isBufferedForFuture()) {
      // console.log("ON PROGRESS BUFFER - ", index);
      onBufferCallback(e, index, true);
    } else {
      // console.log("BUFFERING ENDED 1 - ", index);
      onBufferCallback(e, index, false);
    }
    // if (audioElement.readyState < 4 && audioElement.networkState === 2) {
    //   console.log("ON PROGRESS BUFFER - ", index);
    //   onBufferCallback(e, index, true);
    // } else if (audioElement.readyState === 4) {
    //   console.log("BUFFERING ENDED 1 - ", index);
    //   onBufferCallback(e, index, false);
    // }
  };

  audio.oncanplaythrough = (e) => {
    // const audioElement = e.target as HTMLAudioElement;
    if (isBufferedForFuture()) {
      // console.log("BUFFERING ENDED 2 - ", index);
      onBufferCallback(e, index, false);
    }
    // if (audioElement.readyState === 4) {
    //   console.log("BUFFERING ENDED 2 START - ", index);
    //   setTimeout(() => {
    //     if (audio.readyState === 4) {
    //       console.log("BUFFERING ENDED 2 END - ", index);
    //       onBufferCallback(e, index, false);
    //     } else {
    //       console.log("BUFFERING ENDED 2 FAIL - ", index);
    //     }
    //   }, 1000);
    // }
  };

  function isBufferedForFuture(): boolean {
    // audio: HTMLAudioElement
    const currentTime = Math.round(audio.currentTime * 1000) / 1000;
    const futureTime = Math.min(currentTime + 0.5, audio.duration);

    if (futureTime - currentTime <= 0.3 || audio.duration - currentTime < 0.8) {
      // console.log("BUFFER TOO SMALL - ", audio.readyState, index);
      // return audio.readyState === 4; // This was causing intermittent issues; readystate would become 4, and then quickly go back to 1
      return true;
    }

    if (!audio.buffered.length) {
      // console.log("BUFFERED FAIL - NO BUFFERED - ", currentTime, futureTime);
      return false;
    }

    for (let i = 0; i < audio.buffered.length; i++) {
      // we are rounding down to 3 decimals because sometimes starttime can be after the current time by a few milliseconds
      const startBuffer = Math.round(audio.buffered.start(i) * 1000) / 1000;
      const endBuffer = Math.round(audio.buffered.end(i) * 1000) / 1000;
      if (currentTime >= startBuffer && futureTime <= endBuffer) {
        // console.log(
        //   index,
        //   "BUFFERED SUCCESS AT - ",
        //   currentTime,
        //   futureTime,
        //   audio.buffered.end(0)
        // );
        return true;
      }
    }

    // console.log(
    //   index,
    //   "BUFFERED FAIL AT - ",
    //   currentTime,
    //   futureTime,
    //   audio.duration,
    //   audio.buffered.start(0),
    //   audio.buffered.end(0),
    //   audio.buffered.length,
    //   audio.readyState,
    //   audio.networkState
    // );
    return false;
  }

  return audio;
}
