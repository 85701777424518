import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { FirebaseInstance } from "@/lib/firebase";
import { FIREBASE } from "murflib";
import { API_BASE_URL } from "@/config/config";
import { APIResponse } from "@/types/apiResponse";
import { SERVER_RESPONSES } from "@/constants/serverConstants";
import { storageService } from "@/utils/storage";
export const JSON_TYPE = "application/json";
export const TEXT_TYPE = "text/plain";

async function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  if (config?.headers) {
    if (FirebaseInstance?.auth?.currentUser) {
      const token = await FIREBASE.getIdToken(
        FirebaseInstance?.auth?.currentUser
      );
      config.headers.idToken = `${token}`;
    }
    config.headers.Accept = "application/json";
  }
  if (!config?.headers?.workspaceId) {
    const activeWorkspaceId = window.activeWorkspaceId;
    if (activeWorkspaceId) {
      config.headers.workspaceId = activeWorkspaceId;
    }
  }

  if (!config?.headers?.["CloudFront-Viewer-Country"]) {
    config.headers["CloudFront-Viewer-Country"] =
      window.countryCode || storageService.getItem("countryCode") || "US";
  }

  return config;
}

export const api = axios.create({
  baseURL: API_BASE_URL
});

api.interceptors.request.use(authRequestInterceptor);

api.interceptors.response.use(
  (response: AxiosResponse<APIResponse>) => {
    // if response is other than success then throwing the error
    if (response.data.responseCode !== SERVER_RESPONSES.SUCCESS) {
      return Promise.reject({
        extra: response.data.extra,
        responseMessage: response.data.responseMessage,
        code: response.data.responseCode
      });
    }
    return response;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    console.log("[Axios Err Block]", message);

    return Promise.reject({
      ...error,
      responseMessage: message,
      extra: message,
      code: error.code
    });
  }
);
