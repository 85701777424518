import React, { useState } from "react";
import { DubPlayerCommentButton } from "./DubPlayerCommentButton";
import {
  Box,
  Fade,
  IconButton,
  Popper,
  Stack,
  Typography
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

interface DubPlayerCommentGroupProps {
  groupedIssues: string[];
  dubId: string;
}

export const DubPlayerCommentGroup = ({
  groupedIssues,
  dubId
}: DubPlayerCommentGroupProps): React.ReactNode => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  if (!groupedIssues?.length) {
    return null;
  }
  if (groupedIssues.length === 1) {
    return <DubPlayerCommentButton dubId={dubId} issueId={groupedIssues[0]} />;
  }

  return (
    <>
      <IconButton
        sx={{
          height: 32,
          width: 32,
          zIndex: 2,
          p: "2px"
        }}
        onClick={handleClick}
        aria-describedby={"dub-player-comment-group"}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            height: "100%",
            width: "100%",
            backgroundColor: "common.white"
          }}
        >
          {open ? (
            <CloseRounded
              sx={{
                height: 16,
                width: 16,
                color: "common.black"
              }}
            />
          ) : (
            <Typography variant="subtitle1" color={"common.black"}>
              {groupedIssues.length}
            </Typography>
          )}
        </Box>
      </IconButton>
      <Popper
        id={"dub-player-comment-group"}
        anchorEl={anchorEl}
        open={open}
        placement="top"
        sx={{
          zIndex: 3
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Stack>
              {groupedIssues.map((issueId) => (
                <DubPlayerCommentButton
                  key={issueId}
                  dubId={dubId}
                  issueId={issueId}
                />
              ))}
            </Stack>
          </Fade>
        )}
      </Popper>
    </>
  );
};
