export const pickKeysFromObject = <T extends { [key: string]: any }>(
  obj: T,
  keys: string[] = []
) => {
  const result: { [key: string]: any } = {};

  for (const key of keys) {
    if (obj[key]) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (Array.isArray(obj[key])) {
          result[key] = obj[key].map((item: any) =>
            typeof item === "object" && item !== null ? { ...item } : item
          );
        } else {
          result[key] = { ...obj[key] };
        }
      } else {
        result[key] = obj[key];
      }
    }
  }

  return result;
};

export const sliceArrayIntoChunks = <T extends []>(
  arr: T,
  chunkSize: number
) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const splitObjectIntoChunks = <T extends object>(
  obj: T,
  size: number
) => {
  const payload: any[] = [];
  const objectKeys = Object.keys(obj);
  const chunks = objectKeys.length
    ? sliceArrayIntoChunks(objectKeys as [], size)
    : [];

  chunks.forEach((chunk) => {
    const map = {};
    chunk.forEach((item) => {
      map[item] = obj[item];
    });
    payload.push(map);
  });

  return payload;
};

export function* splitArrayIntoChunks<T, A extends T[]>(
  arr: A,
  n: number
): Generator<T[], void, unknown> {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

// This function takes in an array or an object,
// it splits the array or object into chunks and performs a callback function on each chunk
export function chunkify<SingleElement>({
  data,
  callback,
  chunkSize = 100
}: {
  data: SingleElement[] | { [key: string]: SingleElement };
  callback: (chunk: SingleElement[], index?: number) => any;
  chunkSize?: number;
}) {
  if (Array.isArray(data)) {
    const chunks = splitArrayIntoChunks(data, chunkSize);
    const noOfChunks = Math.ceil(data.length / chunkSize);

    for (let i = 0; i < noOfChunks; i++) {
      const nextValue = chunks.next().value as SingleElement[];
      callback(nextValue, i);
    }
  } else {
    const chunks = splitObjectIntoChunks(data, chunkSize);
    chunks.forEach((chunk, index) => {
      callback(chunk, index);
    });
  }
}

export function convertArrayToMap<T, R extends keyof T>(
  data: T[],
  key: R
): Record<string, T> {
  return data.reduce(
    (acc, curr) => ({ ...acc, [curr[key] as string]: curr }),
    {}
  ) as Record<string, T>;
}

export function deepCloneObject<T>(obj: T): T {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCloneObject(item)) as unknown as T;
  }

  const clonedObj = {} as T;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      (clonedObj as any)[key] =
        typeof value === "function" ? value : deepCloneObject(value);
    }
  }

  return clonedObj;
}
