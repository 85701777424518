import { useCallback, useContext, useEffect, useState } from "react";
import { ANALYTICS } from "murflib";
import mixpanel from "mixpanel-browser";
import Cookies from "js-cookie";
import { AuthUserContext } from "@/lib/firebase";
import { useTypedSelector } from "@/config/configureAppStore";
import { identifyUserFlowUser, initializeUserFlow } from "@/utils/userflow";
import { identifyMixPanelUser, initializeMixpanel } from "@/utils/mixpanel";
import { COOKIE_TYPES } from "@/constants/cookie";
import { MURF_ROLES } from "@/features/user";

const useAnalytics = () => {
  const [mixpanelUserIdentified, setMixpanelUserIdentified] = useState(false);
  const [userFlowIdentified, setUserFlowIdentified] = useState(false);
  const userData = useTypedSelector((state) => state.user);
  const authUser = useContext(AuthUserContext);
  const [isGdpr, setIsGdpr] = useState(true);

  /**
   * @description Attaches an event listener to the window object
   * listens for events triggered by murflib for analytics
   */
  useEffect(() => {
    window.addEventListener("murf-analytics", (event: any) => {
      console.log("Analytics event: ", event.detail);
    });

    return () => {
      window.removeEventListener("murf-analytics", (event: any) => {
        console.log("Analytics event: ", event.detail);
      });
    };
  }, []);

  const initializeAndIdentifyUserFlow = useCallback(() => {
    if (!userFlowIdentified && authUser?.uid && userData) {
      const murfPerm = Cookies.get("murf-cookie-permissions");
      const allowUserFlow =
        userData.role !== MURF_ROLES.USER
          ? true
          : murfPerm
          ? JSON.parse(murfPerm).includes(COOKIE_TYPES.FUNCTIONAL)
          : false;
      if (allowUserFlow) {
        try {
          initializeUserFlow();
          if (authUser && userData) {
            identifyUserFlowUser(authUser, userData);
            setUserFlowIdentified(true);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [userFlowIdentified, authUser, userData]);

  const initializeAndIdentifyUserMixpanel = useCallback(() => {
    if (!mixpanelUserIdentified) {
      const murfPerm = Cookies.get("murf-cookie-permissions");
      const allowMixpanel =
        userData?.role !== MURF_ROLES.USER
          ? true
          : murfPerm
          ? JSON.parse(murfPerm).includes(COOKIE_TYPES.MARKETING_ANALYTICS)
          : false;

      if (allowMixpanel) {
        initializeMixpanel();
        if (mixpanel.__loaded) {
          if (authUser?.uid && userData) {
            identifyMixPanelUser(authUser, userData);
            setMixpanelUserIdentified(true);
          }
        }
      }
    }
  }, [mixpanelUserIdentified, authUser, userData]);

  // listen for studio-analytics-event from murflib
  useEffect(() => {
    const handleGdpr = (event: any) => {
      setIsGdpr(event.detail?.gdpr);
    };
    window.addEventListener("studio-analytics-event", handleGdpr);
    if (!window.murfCookieDialog) {
      ANALYTICS.appendCookieElements();
    }
    if (isGdpr !== undefined) {
      initializeAndIdentifyUserFlow();
      initializeAndIdentifyUserMixpanel();
    }

    return () => {
      window.removeEventListener("studio-analytics-event", handleGdpr);
    };
  }, [
    authUser,
    initializeAndIdentifyUserFlow,
    initializeAndIdentifyUserMixpanel,
    isGdpr,
    userData.apiStatus
  ]);

  return {};
};

export default useAnalytics;
