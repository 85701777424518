import {
  playPause,
  playProgressivePlayer,
  pauseProgressivePlayer,
  seekTo,
  seekProgressivePlayer,
  setProgressivePlayerRenderBuffering,
  MediaPlayerSeekToParams,
  MediaPlayerContextType,
  ProgressiveAudioPlayerContextType
} from "@/features/mediaPlayer";
import { useEffect, useMemo, useCallback, useRef } from "react";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { useSnackbar } from "@/components/elements/MurfSnackbar";

interface UseMediaPlayerManagerArgs {
  players: MediaPlayerContextType[];
  progressivePlayers?: ProgressiveAudioPlayerContextType[];
  primaryPlayerIndex: number;
}

export function useMediaPlayerManager({
  players,
  progressivePlayers,
  primaryPlayerIndex
}: UseMediaPlayerManagerArgs) {
  // const [lastBuffering, setLastBuffering] = useState(false);
  const dispatch = useAppDispatch();
  const { showError } = useSnackbar();
  const renderingBlockIds = useTypedSelector(
    (state) => state.projectUtility.renderingBlocks
  );
  const renderUpdatedBlocksQueue = useTypedSelector(
    (state) => state.projectUtility.renderUpdatedBlocksQueue
  );
  const renderCircuitBreaker = useTypedSelector(
    (state) => state.projectUtility.renderCircuitBreaker
  );
  const playersRef = useRef<MediaPlayerContextType[]>(players);
  playersRef.current = players;
  const progressivePlayersRef = useRef<
    ProgressiveAudioPlayerContextType[] | undefined
  >(progressivePlayers);
  progressivePlayersRef.current = progressivePlayers;
  const lastBufferingRef = useRef<boolean>(false);

  const anyBufferingRef = useRef<boolean>(false);
  const anyPlayingRef = useRef<boolean>(false);
  const allPlaying = useMemo(() => {
    return (
      players.every((player) => player.state.isPlaying) &&
      (!progressivePlayers ||
        progressivePlayers?.length === 0 ||
        progressivePlayers.every((player) => player.state.isPlaying))
    );
  }, [players, progressivePlayers]);

  const anyPlaying = useMemo(() => {
    return (
      players.some((player) => player.state.isPlaying) ||
      (progressivePlayers?.length
        ? progressivePlayers!.some((player) => player.state.isPlaying)
        : false)
    );
  }, [players, progressivePlayers]);

  anyPlayingRef.current = anyPlaying;

  const anyBuffering = useMemo(() => {
    return (
      players.some((player) => player.state.buffering) ||
      (progressivePlayers?.length
        ? progressivePlayers.some(
            (player) => player.state.buffering || player.state.renderBuffering
          )
        : false)
    );
  }, [players, progressivePlayers]);
  anyBufferingRef.current = anyBuffering;

  const seekToAll = useCallback(
    ({ amount, type }: MediaPlayerSeekToParams) => {
      playersRef.current.forEach((player, index) => {
        if (index !== primaryPlayerIndex) {
          // because the primary player would have already changed
          seekTo(player?.dispatch, { amount: amount, type });
        }
      });
      progressivePlayersRef.current?.forEach((player) => {
        seekProgressivePlayer({
          dispatch: player?.dispatch,
          payload: { amount: amount, type }
        });
      });
    },
    [primaryPlayerIndex]
  );

  // if (progressivePlayers && progressivePlayers?.length > 0) {
  //   if (
  //     Math.abs(
  //       progressivePlayers[0]?.state?.mediaProgress -
  //         players[0]?.state?.mediaProgress
  //     ) > 0.4
  //   ) {
  //     console.log(
  //       `${
  //         players[1]?.state?.mediaProgress
  //       } | ${players[0]?.state?.mediaProgress.toFixed(3)}(${(
  //         players[0]?.state?.mediaProgress - players[1]?.state?.mediaProgress
  //       ).toFixed(3)}) | ${progressivePlayers[0]?.state?.mediaProgress.toFixed(
  //         3
  //       )}(${(
  //         progressivePlayers[0]?.state?.mediaProgress -
  //         players[0]?.state?.mediaProgress
  //       ).toFixed(3)})`
  //     );
  //   }
  // }

  const checkVariantPlayerProgress = useCallback(() => {
    const mediaProgress =
      playersRef.current[primaryPlayerIndex].state.mediaProgress;
    const variantMediaPlayers = playersRef.current.some(
      (player) => player.state.mediaProgress !== mediaProgress
    );
    if (
      variantMediaPlayers ||
      !progressivePlayersRef.current ||
      progressivePlayersRef.current?.length === 0
    )
      return variantMediaPlayers;

    const variantProgressivePlayers = progressivePlayersRef.current?.some(
      (player) => player.state.mediaProgress !== mediaProgress
    );

    return variantProgressivePlayers;
  }, [primaryPlayerIndex]);

  const getPlayersMaxVariance = useCallback(() => {
    const mediaProgress =
      playersRef.current[primaryPlayerIndex].state.mediaProgress;
    let maxPlayerVariance = 0;
    playersRef.current.forEach((player) => {
      const variance = Math.abs(player.state.mediaProgress - mediaProgress);
      if (variance > maxPlayerVariance) {
        maxPlayerVariance = variance;
      }
    });

    progressivePlayersRef.current?.forEach((player) => {
      const variance = Math.abs(player.state.mediaProgress - mediaProgress);
      if (variance > maxPlayerVariance) {
        maxPlayerVariance = variance;
      }
    });

    return maxPlayerVariance;
  }, [primaryPlayerIndex]);

  const playAll = useCallback(
    ({ skipPrimary }: { skipPrimary: boolean } = { skipPrimary: false }) => {
      const variantPlayerProgressPresent = checkVariantPlayerProgress();
      if (variantPlayerProgressPresent) {
        const referenceMediaProgress =
          playersRef.current[primaryPlayerIndex].state.mediaProgress;
        seekToAll({ amount: referenceMediaProgress });
      }
      const playersToUpdate = !skipPrimary
        ? playersRef.current
        : playersRef.current.filter((_, i) => i !== primaryPlayerIndex);
      playersToUpdate.forEach((player) => {
        if (!player.state.isPlaying) {
          playPause(player?.dispatch, { playState: true });
        }
      });
      progressivePlayersRef.current?.forEach((player) => {
        if (!player.state.isPlaying) {
          // console.log("MANAGER PLAY - ", player.currentPlayingBlockId);
          playProgressivePlayer({ dispatch: player?.dispatch });
        }
      });
    },
    [checkVariantPlayerProgress, seekToAll, primaryPlayerIndex]
  );

  const pauseAll = useCallback(
    (
      {
        skipPrimary,
        buffering
      }: { skipPrimary?: boolean; buffering?: boolean } = {
        skipPrimary: false,
        buffering: false
      }
    ) => {
      const playersToUpdate = !skipPrimary
        ? playersRef.current
        : playersRef.current.filter((_, i) => i !== primaryPlayerIndex);
      playersToUpdate.forEach((player) => {
        playPause(player?.dispatch, { playState: false });
      });
      progressivePlayersRef.current?.forEach((player) => {
        if (player.state.isPlaying) {
          // console.log("MANAGER PAUSE - ", player.currentPlayingBlockId);
          pauseProgressivePlayer({
            dispatch: player?.dispatch,
            payload: { buffering }
          });
        }
      });
      const referenceMediaProgress =
        playersRef.current[primaryPlayerIndex].state.mediaProgress;
      seekToAll({ amount: referenceMediaProgress });
    },
    [seekToAll, primaryPlayerIndex]
  );

  // attaching an callbacks to the primary player
  useEffect(() => {
    if (!players[primaryPlayerIndex].callbackFunctions.current?.onProgress) {
      players[primaryPlayerIndex].callbackFunctions.current = {
        ...players[primaryPlayerIndex].callbackFunctions.current,
        onProgress: (progress: number) => {
          seekToAll({ amount: progress });
        },
        onPlay: () => {
          if (!anyPlayingRef.current && !anyBufferingRef.current) {
            playAll({ skipPrimary: true });
          }
        },
        onPause: () => {
          if (anyPlayingRef.current) {
            pauseAll({ skipPrimary: true });
          }
        }
      };
    }
  }, [
    players,
    primaryPlayerIndex,
    seekToAll,
    pauseAll,
    playAll,
    anyBufferingRef
  ]);

  // Pausing the players if any of them is buffering
  // and resuming them when all of them are done buffering
  useEffect(() => {
    if (allPlaying && anyBuffering && !lastBufferingRef.current) {
      lastBufferingRef.current = true;
      // console.log("BUFFER MAIN PAUSE");
      pauseAll({ buffering: true });
    } else if (!anyBuffering && lastBufferingRef.current) {
      lastBufferingRef.current = false;
      // console.log("BUFFER MAIN PLAY");
      playAll();
    } else if (anyPlaying && anyBuffering) {
      lastBufferingRef.current = true;
      // console.log("BUFFER SECONDARY PAUSE");
      pauseAll({ buffering: true });
    }
  }, [
    anyBuffering,
    lastBufferingRef,
    anyPlaying,
    allPlaying,
    playAll,
    pauseAll
  ]);

  // check if the current playing block in progressive audio player is being rendered
  useEffect(() => {
    if (
      progressivePlayers &&
      progressivePlayers?.length > 0 &&
      renderingBlockIds &&
      renderingBlockIds.length > 0 &&
      !progressivePlayers[0].state.renderBuffering
    ) {
      const currentPlayingBlockId = progressivePlayers[0].currentPlayingBlockId;
      const currentBlockIsRendering = renderingBlockIds.some(
        (blockId) => blockId === currentPlayingBlockId
      );
      if (currentBlockIsRendering) {
        // set the renderBuffering flag to true
        setProgressivePlayerRenderBuffering({
          dispatch: progressivePlayers[0].dispatch,
          payload: true
        });
      }
    }
  }, [progressivePlayers, renderingBlockIds]);

  // if the renderFlag is true, but the current playing block is not being rendered, unset the renderFlag
  useEffect(() => {
    if (
      progressivePlayers &&
      progressivePlayers?.length > 0 &&
      progressivePlayers[0].state.renderBuffering
    ) {
      const currentPlayingBlockId = progressivePlayers[0].currentPlayingBlockId;
      const currentBlockIsRendering = renderingBlockIds.some(
        (blockId) => blockId === currentPlayingBlockId
      );
      const currentBlockHasNotUpdated = renderUpdatedBlocksQueue.some(
        (blockId) => blockId === currentPlayingBlockId
      );

      if (!currentBlockIsRendering && !currentBlockHasNotUpdated) {
        setTimeout(() => {
          // delay for the audio to load
          setProgressivePlayerRenderBuffering({
            dispatch: progressivePlayers[0].dispatch,
            payload: false
          });
        }, 1000);
      }
    }
  }, [progressivePlayers, renderingBlockIds, renderUpdatedBlocksQueue]);

  // variance self-healing
  useEffect(() => {
    // a check that runs every 500ms to see if the players are in sync
    let interval: NodeJS.Timeout | null = null;
    if (allPlaying && !anyBuffering) {
      interval = setInterval(() => {
        const maxVariance = getPlayersMaxVariance();

        if (maxVariance > 0.5) {
          console.log(`SELF HEALING!! VARIANCE: ${maxVariance}`);
          const referenceMediaProgress =
            playersRef.current[primaryPlayerIndex].state.mediaProgress;
          seekToAll({ amount: referenceMediaProgress });
        }
      }, 2000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [
    getPlayersMaxVariance,
    seekToAll,
    pauseAll,
    playAll,
    primaryPlayerIndex,
    allPlaying,
    anyBuffering
  ]);

  // Pause all players if renderCircuitBreaker is true
  useEffect(() => {
    if (renderCircuitBreaker && (allPlaying || anyBuffering)) {
      pauseAll();
      showError("Blocks updated. Please play again.");
    }
  }, [
    renderCircuitBreaker,
    pauseAll,
    allPlaying,
    anyBuffering,
    dispatch,
    showError
  ]);

  return {
    playAll,
    pauseAll,
    seekToAll,
    checkVariantPlayerProgress,
    allPlaying,
    anyBuffering
  };
}
