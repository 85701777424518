import { MurfInputLabel } from "@/components/elements/MurfInputLabel";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";

interface OptionsProps {
  options: {
    title: string;
    value: string;
    description?: string;
  }[];
  label: string;
  onChange: (value: string) => void;
  selectedValue: string;
}

const Options = ({ options, label, onChange, selectedValue }: OptionsProps) => {
  return (
    <>
      {label ? <MurfInputLabel sx={{ mb: 1 }}>{label}</MurfInputLabel> : null}
      <Box display="flex" gap={1} alignItems="center">
        <ButtonGroup>
          {options.map((option) => (
            <Button
              variant={
                selectedValue === option.value ? "contained" : "outlined"
              }
              color="secondary"
              onClick={() => onChange(option.value)}
              key={option.value}
            >
              {option.title}
            </Button>
          ))}
        </ButtonGroup>
        {
          <Typography variant="body2" color="text.secondary">
            {
              options.find((option) => option.value === selectedValue)
                ?.description
            }
          </Typography>
        }
      </Box>
    </>
  );
};

export default Options;
