import React from "react";

const CreditsIcon = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      style={style}
    >
      <path
        fill="#C80"
        fillRule="evenodd"
        d="M9.997 17.501c4.603 0 8.334-2.985 8.334-6.666v-2.06h-.405c-1.085-2.674-4.224-4.607-7.929-4.607-3.704 0-6.843 1.933-7.928 4.608h-.405v2.059c0 3.681 3.731 6.666 8.333 6.666z"
        clipRule="evenodd"
      ></path>
      <ellipse
        cx="9.997"
        cy="8.75"
        fill="#FFD740"
        rx="8.333"
        ry="6.25"
      ></ellipse>
      <path
        fill="#FFECB3"
        fillRule="evenodd"
        d="M18.315 9.167a4.77 4.77 0 00.018-.414v-.006C18.331 5.297 14.601 2.5 10 2.5v6.667h8.315z"
        clipRule="evenodd"
      ></path>
      <ellipse
        cx="10.002"
        cy="8.751"
        fill="#D69A00"
        rx="6.111"
        ry="4.583"
      ></ellipse>
      <path
        fill="#FFD740"
        d="M9.761 5.71a.25.25 0 01.474 0l.502 1.504c.056.17.215.285.395.285h1.872a.25.25 0 01.15.449L11.764 9a.417.417 0 00-.136.485l.598 1.518a.25.25 0 01-.361.306l-1.652-.998a.417.417 0 00-.43 0l-1.651.998a.25.25 0 01-.362-.306l.598-1.518A.417.417 0 008.232 9l-1.39-1.052a.25.25 0 01.15-.45h1.872c.18 0 .339-.114.396-.284L9.76 5.71z"
      ></path>
    </svg>
  );
};

export default CreditsIcon;
