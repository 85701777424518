import { RootState } from "@/config/configureAppStore";
import { pricingApi } from "@/features/pricing/api";
import {
  AutoPayData,
  BusinessDetails,
  PAYMENT_MODES,
  PricingPlan
} from "@/features/pricing/types";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const pricingThunks = {
  fetchPricingPlans: createAsyncThunk("fetchPricingPlans", async () => {
    const res = await pricingApi.fetchPricingPlans();

    const planCategories = res.data?.responseData?.planCategories;

    if (!planCategories.some((plan) => plan.category === "DUB_CUSTOM_PLAN")) {
      planCategories.push({
        category: "DUB_CUSTOM_PLAN" as PricingPlan["category"],
        planId: "DUB_CUSTOM_V0",
        noOfProject: 50000,
        maxUser: 0,
        creditsPerUnit: 0,
        characterLimitFlexible: true,
        qualityAssurance: false,
        selfEditAllowed: false,
        noWaterMark: false
      });
    }

    return planCategories;
  }),
  fetchPricingPreview: createAsyncThunk(
    "fetchPricingPreview",
    async (
      { abortController }: { abortController?: AbortController },
      { getState, rejectWithValue }
    ) => {
      const state = getState() as RootState;
      const {
        checkoutPlan,
        topupValue,
        paymentMode,
        address,
        businessDetails,
        selectedCardId,
        previewData,
        isBusiness
      } = state.pricingDialog.checkoutDetails;

      if (!checkoutPlan) {
        return rejectWithValue("Checkout plan not found");
      }

      console.log("selectedCard", previewData);
      const selectedCard =
        previewData?.responseData?.paymentMethodSummaries?.find(
          (card) => card.paymentMethodId === selectedCardId
        );

      console.log(checkoutPlan.planId);
      const res = await pricingApi.fetchPricingPreview({
        pricingPlan: checkoutPlan.planId,
        topupValue,
        abortController,
        billingCity:
          paymentMode === PAYMENT_MODES.NEW
            ? address.city
            : selectedCard?.billingCity,
        billingState:
          paymentMode === PAYMENT_MODES.NEW
            ? address.state
            : selectedCard?.billingState,
        billingCountry:
          paymentMode === PAYMENT_MODES.NEW
            ? address.country
            : selectedCard?.billingCountry,
        billingPostalCode:
          paymentMode === PAYMENT_MODES.NEW
            ? address.postal_code
            : selectedCard?.billingPostalCode,
        taxType: isBusiness ? businessDetails.taxType : "",
        taxValue: isBusiness ? businessDetails.taxId : ""
      });

      return res.data;
    }
  ),
  setBusinessDetails: createAsyncThunk(
    "setBusinessDetails",
    (data: Partial<BusinessDetails>, { getState }) => {
      const state = getState() as RootState;
      const { businessDetails } = state.pricingDialog.checkoutDetails;
      return { ...businessDetails, ...data };
    }
  ),
  updateAutoPayData: createAsyncThunk(
    "updateAutoPayData",
    async (autoPayData: AutoPayData, { rejectWithValue }) => {
      const res = await pricingApi.updateAutoPayData(autoPayData);
      if (!res?.data?.responseData?.workspaceId) {
        return rejectWithValue("Failed to update auto pay data");
      }
      return {
        ...autoPayData
      };
    }
  )
};
