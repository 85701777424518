import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  FormControlLabel,
  IconButtonProps,
  LinearProgress,
  Popover,
  Stack,
  styled,
  Typography
} from "@mui/material";
import { ExportType, exportConfigData } from "./Config";
import {
  ElementType,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState
} from "react";
import Options from "./Options";
import {
  Audiotrack,
  CheckCircleRounded,
  Subtitles,
  Videocam
} from "@mui/icons-material";
import { generateExportConfig } from "./util";
import { projectDetailsApi } from "../../api";
import pollFactory from "@/utils/PollFactory";
import { STATUS } from "@/constants/status";
import { useAppDispatch } from "@/config/configureAppStore";
import { setSnackBar } from "@/reducers/slices/dialogSlice/dialogSlice";
import { TASK_STATUS_TYPES } from "@/types/apiResponse";
import { useParams } from "react-router-dom";
import { ExportLevel } from "../../types/export";
import { IFileData } from "../../types";
import { getMixpanelLanguageName, trackMixpanelEvent } from "@/utils/mixpanel";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { trackUserFlowEvent } from "@/utils/userflow";
import { userStateApi } from "@/features/userState/api";
import { deepCloneObject } from "@/utils/json";

const icons = {
  VIDEO: <Videocam />,
  AUDIO: <Audiotrack />,
  SUBTITLES: <Subtitles />
};

const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    borderRadius: theme.spacing(2),
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    width: 440,
    maxHeight: 480,
    overflowY: "auto",
    gap: theme.spacing(4),
    display: "flex",
    flexDirection: "column"
  }
}));

interface ExportPopupProps {
  fileId: string;
  ButtonComponent: ElementType;
  buttonProps: ButtonProps | IconButtonProps;
  fileType: IFileData["fileType"];
  dubId?: string;
  exportLevel: ExportLevel;
  language: string;
  dubVersion?: string;
}

const getConfig = (type: ExportType) => deepCloneObject(exportConfigData[type]);

const Content = ({
  fileId,
  dubId,
  exportStatus,
  setExportStatus,
  onClose,
  exportLevel,
  fileType,
  language,
  dubVersion,
  projectId
}: {
  projectId?: string;
  fileId: string;
  dubId?: string;
  onClose: () => void;
  exportStatus: STATUS;
  setExportStatus: (status: STATUS) => void;
  exportLevel: ExportLevel;
  fileType: IFileData["fileType"];
  language: string;
  dubVersion?: string;
}) => {
  const [currentConfig, setCurrentConfig] = useState(() =>
    getConfig(fileType === "BG_AUDIO" ? "AUDIO" : "VIDEO")
  );
  const [exportProgress, setExportProgress] = useState(0);
  const [cancelStatus, setCancelStatus] = useState(STATUS.IDLE);
  const downloadUrl = useRef("");
  const exportPollerId = useRef<string | undefined>("");
  const taskIdRef = useRef<string | null>("");
  const abortController = useRef<AbortController>();

  const dispatch = useAppDispatch();

  const exportTypes = useMemo(() => {
    const typesArray = Object.values(exportConfigData);
    if (fileType === "BG_AUDIO") {
      return typesArray.filter((type) => type.value !== "VIDEO");
    }
    return typesArray;
  }, [fileType]);

  const changeExportType = (type: ExportType) => {
    setCurrentConfig(getConfig(type));
  };
  const changeConfig = () => {
    setCurrentConfig(deepCloneObject(currentConfig));
  };

  const handleDownloadError = (reason: string) => {
    setExportStatus(STATUS.IDLE);
    dispatch(
      setSnackBar({
        message: reason || "Failed to download",
        type: "error",
        open: true
      })
    );
  };

  const handleComplete = () => {
    setExportStatus(STATUS.IDLE);
    onClose();
  };

  const handleCancel = () => {
    if (abortController.current) abortController.current.abort();
    if (!taskIdRef.current) {
      setExportStatus(STATUS.IDLE);
      return;
    }
    if (cancelStatus === STATUS.LOADING) return;
    const exportConfig = generateExportConfig(currentConfig, exportLevel);

    trackMixpanelEvent(MIXPANEL_EVENTS.EXPORT_CANCELLED, {
      "Entry Point": exportLevel,
      "Project ID": projectId,
      "Dub ID": dubId,
      "Dub Version": dubVersion,
      "File Type": exportConfig.exportAs,
      "File Format": exportConfig.format,
      "Burn Subtitles": exportConfig.burnSubtitlesToVideo,
      "Separate Subtitle File": exportConfig.exportAs === "VIDEO_WITH_VTT",
      "File Quality": exportConfig.rate
    });
    setCancelStatus(STATUS.LOADING);
    userStateApi
      .cancelTask(taskIdRef.current)
      .catch(() => {
        dispatch(
          setSnackBar({
            message: "Failed to cancel",
            type: "error",
            open: true
          })
        );
      })
      .finally(() => {
        setExportStatus(STATUS.IDLE);
        setCancelStatus(STATUS.IDLE);
        if (exportPollerId.current) {
          pollFactory.cancelPoller(exportPollerId.current);
        }
      });
  };

  const handleExport = () => {
    if (!projectId || exportStatus !== STATUS.IDLE) return;
    setExportStatus(STATUS.LOADING);
    const exportConfig = generateExportConfig(currentConfig, exportLevel);
    abortController.current = new AbortController();

    trackMixpanelEvent(MIXPANEL_EVENTS.EXPORT_START, {
      "Entry Point": exportLevel,
      "Project ID": projectId,
      "Dub ID": dubId,
      "Dub Version": dubVersion,
      "File Type": exportConfig.exportAs,
      "File Format": exportConfig.format,
      "Burn Subtitles": exportConfig.burnSubtitlesToVideo,
      "Separate Subtitle File": exportConfig.exportAs === "VIDEO_WITH_VTT",
      "File Quality": exportConfig.rate
    });

    projectDetailsApi
      .exportDubs({
        projectId,
        dubId,
        dubVersion,
        fileId,
        exportConfig,
        signal: abortController.current.signal
      })
      .then((res) => {
        const taskId = res.data.extra;
        taskIdRef.current = taskId;
        if (taskId) {
          // poll for status
          exportPollerId.current = pollFactory.getPoller({
            fn: () =>
              new Promise((resolve, reject) => {
                projectDetailsApi
                  .exportDubsStatus({
                    projectId,
                    taskId
                  })
                  .then((res) => {
                    resolve(res.data.responseData);
                  })
                  .catch((e) => {
                    if (e?.code !== "ERR_NETWORK") {
                      reject({
                        extra: e.extra
                      });
                      handleDownloadError(e?.extra);
                    } else {
                      if (e?.code === "ERR_NETWORK") {
                        console.log("No Internet Connection !");
                      }

                      reject(e);
                    }
                  });
              }),
            validate: (task: any) => {
              if (task?.taskStatus === TASK_STATUS_TYPES.COMPLETED) {
                setSnackBar({
                  message: "Downloading all dubs",
                  type: "success",
                  open: true
                });
                setExportStatus(STATUS.SUCCESS);
                downloadUrl.current = task.extra;
                if (downloadUrl.current) {
                  trackMixpanelEvent(MIXPANEL_EVENTS.EXPORT_SUCCESS, {
                    "Entry Point": exportLevel,
                    "Project ID": projectId,
                    "Dub ID": dubId,
                    "Dub Version": dubVersion,
                    "File Type": exportConfig.exportAs,
                    "File Format": exportConfig.format,
                    "Burn Subtitles": exportConfig.burnSubtitlesToVideo,
                    "Separate Subtitle File":
                      exportConfig.exportAs === "VIDEO_WITH_VTT",
                    "File Quality": exportConfig.rate
                  });
                }

                return true;
              } else if (task?.taskStatus === TASK_STATUS_TYPES.FAILED) {
                handleDownloadError(task.extra);
                return true;
              }
              if (typeof task?.percentageCompletion === "number")
                setExportProgress(task.percentageCompletion);
              return false;
            },
            interval: exportLevel === "FILE" ? 5000 : 2000
          });
        }
      })
      .catch(() => {
        handleDownloadError("Failed to export");
      });
  };

  const loading = exportStatus === STATUS.LOADING;

  return (
    <>
      <div>
        <Typography variant="h6" color="text.primary">
          Export Dub
        </Typography>
        <Typography variant="body2" color="text.primary">
          Export dub in multiple formats
        </Typography>
      </div>
      {loading || exportStatus === STATUS.SUCCESS ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
          width="100%"
        >
          {loading ? (
            <LinearProgress
              sx={{ width: "60%", borderRadius: 2 }}
              variant="determinate"
              color="success"
              value={exportProgress}
            />
          ) : (
            <CheckCircleRounded
              sx={{ height: 40, width: 40 }}
              color="success"
            />
          )}
          <Typography variant="body1" color="text.primary">
            {loading
              ? "We are preparing your files"
              : "Your files are packaged"}
          </Typography>
        </Box>
      ) : (
        <>
          <Box display="flex" gap={2}>
            {exportTypes.map((type) => (
              <Button
                color="secondary"
                variant={
                  currentConfig.value === type.value ? "contained" : "outlined"
                }
                sx={{
                  paddingY: 2,
                  paddingX: 5,
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.5,
                  borderRadius: 3
                }}
                onClick={() => changeExportType(type.value)}
                key={type.value}
              >
                {icons[type.value]}
                <Typography variant="subtitle2">{type.title}</Typography>
              </Button>
            ))}
          </Box>
          <Box>
            <Options
              options={currentConfig.formats}
              label="Format"
              selectedValue={currentConfig.selectedFormat}
              onChange={(value) => {
                currentConfig.selectedFormat = value;
                changeConfig();
              }}
            />
          </Box>
          {currentConfig?.settings ? (
            <Stack>
              {currentConfig.settings.checkbox?.map((checkbox: any) => {
                if (
                  checkbox.canShow &&
                  !checkbox.canShow({ config: currentConfig, exportLevel })
                ) {
                  return null;
                }
                return (
                  <div key={checkbox?.value}>
                    <FormControlLabel
                      label={checkbox.label}
                      control={<Checkbox />}
                      checked={checkbox.checked}
                      onChange={() => {
                        checkbox.checked = !checkbox.checked;
                        changeConfig();
                      }}
                    />
                    {checkbox.checked ? (
                      <Stack ml={4}>
                        {checkbox.settings?.options ? (
                          <Options
                            options={checkbox.settings.options.values}
                            selectedValue={
                              checkbox.settings.options.selectedValue
                            }
                            label={checkbox.settings.options.label}
                            onChange={(value: string) => {
                              checkbox.settings.options.selectedValue = value;
                              changeConfig();
                            }}
                          />
                        ) : null}
                        {checkbox.settings?.checkbox ? (
                          <>
                            {checkbox.settings.checkbox.map(
                              (subCheckbox: any) => (
                                <FormControlLabel
                                  label={subCheckbox.label}
                                  control={<Checkbox />}
                                  checked={subCheckbox.checked}
                                  onChange={() => {
                                    subCheckbox.checked = !subCheckbox.checked;
                                    changeConfig();
                                  }}
                                  sx={{ mt: 2 }}
                                  key={subCheckbox.value}
                                />
                              )
                            )}
                          </>
                        ) : null}
                      </Stack>
                    ) : null}
                  </div>
                );
              })}
              {currentConfig.settings.options ? (
                <Options
                  options={currentConfig.settings.options.values}
                  selectedValue={currentConfig.settings.options.selectedValue}
                  onChange={(value: string) => {
                    if (currentConfig.settings?.options) {
                      currentConfig.settings.options.selectedValue = value;
                      changeConfig();
                    }
                  }}
                  label={currentConfig.settings.options.label}
                />
              ) : null}
            </Stack>
          ) : null}
        </>
      )}
      <Box display="flex" gap={2} justifyContent="end">
        <Button
          variant="outlined"
          color="secondary"
          onClick={loading ? handleCancel : onClose}
          disabled={cancelStatus === STATUS.LOADING}
        >
          {loading ? "Cancel" : "Close"}
        </Button>
        {exportStatus === STATUS.SUCCESS ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              const eventTrackerPayload = {
                Language: getMixpanelLanguageName(language),
                "Entry Point": "Dub",
                "Project ID": projectId,
                "Dub ID": dubId,
                "Dub Version": dubVersion
              };
              trackMixpanelEvent(MIXPANEL_EVENTS.DOWNLOAD, eventTrackerPayload);
              trackUserFlowEvent(MIXPANEL_EVENTS.DOWNLOAD, {
                ...eventTrackerPayload,
                "Entry Point": "Project Detail Dub"
              });
              handleComplete();
            }}
            href={downloadUrl.current}
          >
            Download
          </Button>
        ) : (
          <Button
            disabled={loading}
            variant="contained"
            color="secondary"
            onClick={handleExport}
          >
            Export
          </Button>
        )}
      </Box>
    </>
  );
};

const ExportPopup = ({
  fileId,
  fileType,
  dubId,
  exportLevel,
  dubVersion,
  language,
  ButtonComponent,
  buttonProps
}: ExportPopupProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [exportStatus, setExportStatus] = useState(STATUS.IDLE);
  const { projectId } = useParams();
  const closeExportPopup = useCallback(() => setAnchorEl(null), []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    trackMixpanelEvent(MIXPANEL_EVENTS.EXPORT_DROPDOWN_OPENED, {
      "Entry Point": exportLevel === "FILE" ? "File" : "Dub",
      "Project ID": projectId,
      "File ID": fileId
    });
  };

  const exportGoingOn =
    exportStatus === STATUS.LOADING || exportStatus === STATUS.SUCCESS;
  const isOpen = Boolean(anchorEl);

  return (
    <>
      <ButtonComponent {...buttonProps} onClick={handleClick} ref={buttonRef} />
      <StyledPopover
        open={exportGoingOn || isOpen}
        onClick={(e) => e.stopPropagation()}
        onClose={closeExportPopup}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
        slotProps={{
          paper: {
            elevation: 3
          }
        }}
        hidden={!isOpen && exportGoingOn}
      >
        <Content
          onClose={closeExportPopup}
          projectId={projectId}
          fileId={fileId}
          dubId={dubId}
          exportLevel={exportLevel}
          fileType={fileType}
          dubVersion={dubVersion}
          language={language}
          exportStatus={exportStatus}
          setExportStatus={setExportStatus}
        />
      </StyledPopover>
    </>
  );
};

export default memo(ExportPopup);
