import { IRequestChanges } from "@/features/comments";
import { api } from "@/lib/axios";
import { DubPreviewDetails } from "@/reducers/slices/dubPreviewSlice/types";
import { APIResponse } from "@/types/apiResponse";

export const dubPreviewApi = {
  fetchDubPreview: ({
    projectId,
    fileId,
    dubId,
    dubVersion
  }: {
    projectId: string;
    fileId: string;
    dubId: string;
    dubVersion: string;
  }) =>
    api.get<APIResponse<DubPreviewDetails>>(
      `/project/${projectId}/dub-response/file/${fileId}/dub/${dubId}?dubVersion=${dubVersion}`
    ),
  discardDraft: ({ fileId, dubId }: { fileId: string; dubId: string }) =>
    api.post(
      `/file/${fileId}/dub/${dubId}/translation-vo/discard-draft?workspaceId=${window.activeWorkspaceId}`
    ),
  fetchDraftVersion: ({
    projectId,
    fileId,
    dubId
  }: {
    projectId: string;
    fileId: string;
    dubId: string;
  }) =>
    api.get<APIResponse<string>>(
      `project/${projectId}/get-draft-version/file/${fileId}/dub/${dubId}`
    ),
  requestQc: ({
    projectId,
    fileId,
    dubId,
    workspaceId
  }: {
    projectId: string;
    fileId: string;
    dubId: string;
    workspaceId: string;
  }) => {
    return api.post<APIResponse<string>>(
      `project/${projectId}/dub-qc-request/file/${fileId}/dub/${dubId}`,
      {},
      {
        params: {
          workspaceId
        }
      }
    );
  },
  requestChanges: ({
    fileId,
    dubId,
    dubVersion,
    workspaceId,
    projectId,
    isInternal
  }: IRequestChanges) =>
    api.post(
      `/change-request/submit/file/${fileId}/dub/${dubId}/version/${dubVersion}?workspaceId=${workspaceId}&projectId=${projectId}&isInternal=${isInternal}`,
      undefined
    ),
  generateShareLink: ({
    fileId,
    dubId,
    projectId,
    dubVersion
  }: {
    projectId: string;
    fileId: string;
    dubId: string;
    dubVersion: string;
  }) =>
    api.post<APIResponse<string>>(
      `project/${projectId}/create-share-link/file/${fileId}/dub/${dubId}?dubVersion=${dubVersion}`,
      {}
    ),
  shareLinkPassword: ({
    fileId,
    dubId,
    password,
    dubVersion,
    projectId
  }: {
    fileId: string;
    dubId: string;
    password: string;
    dubVersion: string;
    projectId: string;
  }) =>
    api.post(
      `project/${projectId}/share-link-password/file/${fileId}/dub/${dubId}?dubVersion=${dubVersion}`,
      password,
      {
        headers: {
          "Content-Type": "text/plain"
        }
      }
    )
};
