export const DUB_PREVIEW_MAIN_PLAYER_ID = "dub-preview-main-player";
export const DUB_PREVIEW_ORIGINAL_PLAYER_ID = "dub-preview-original-player";
export const DUB_PREVIEW_SIDEBAR_WIDTH = 380;

export type Client_Sidebar_Screens =
  | "ALL_ISSUES"
  | "CREATE_ISSUE"
  | "ISSUE_DETAIL";

export type DubPreviewTopBarVariant = "default" | "compact";

export const MAX_DUB_VERSIONS_ALLOWED = 5;
