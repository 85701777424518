import {
  ExpandLess,
  ExpandMore,
  FolderSpecialRounded,
  SettingsRounded,
  UpgradeRounded
} from "@mui/icons-material";
import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { QUERY_PARAMS_FILTERS, STATUS_FILTER } from "./constants";
import { useAppDispatch, useTypedSelector } from "@/config/configureAppStore";
import { IClientProjectHomeData } from "@/features/clientHome";
import CreditsIcon from "@/components/assets/CreditsIcon";
import { setPricingDialog } from "@/reducers/slices/pricingDialogSlice/pricingDialogSlice";
import { trackMixpanelEvent } from "@/utils/mixpanel";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";

const EMPTY_ARRAY: IClientProjectHomeData[] = [];

const ClientSideBar = (): React.ReactNode => {
  const navigate = useNavigate();
  const [expandProjectsMenu, setExpandProjectsMenu] = useState(true);
  const [expandSettingsMenu, setExpandSettingsMenu] = useState(true);

  const location = useLocation();
  const dispatch = useAppDispatch();

  const currentActiveTab = useMemo(() => {
    const _search = new URLSearchParams(location.search);
    if (_search.get(STATUS_FILTER)?.length) {
      return _search.get(STATUS_FILTER);
    }
    if (location.pathname.startsWith("/profile/workspace/credit-utilization")) {
      return "CREDIT_UTILIZATION";
    }
    if (location.pathname.startsWith("/profile/workspace/manage")) {
      return "WORKSPACE_MANAGE";
    }
    if (location.pathname.startsWith("/profile/workspace")) {
      return "WORKSPACE";
    }
    if (location.pathname.startsWith("/profile/user")) {
      return "PROFILE_USER";
    }

    return null;
  }, [location]);

  const _allProjects = useTypedSelector(
    (state) => state.clientHome.allProjects
  );

  const { usedCredits, assignedCredits } = useTypedSelector(
    (state) => state.user.activeWorkspaceData || ({} as any)
  );
  const availableCredits = Math.max(
    0,
    (assignedCredits || 0) - (usedCredits || 0)
  );

  const allProjects = useMemo(() => {
    if (_allProjects) {
      return Object.values(_allProjects);
    }
    return EMPTY_ARRAY;
  }, [_allProjects]);

  const unArchivedData = useMemo(() => {
    return allProjects.filter((data) => !data.archived);
  }, [allProjects]);

  const draftCount = useMemo(() => {
    return unArchivedData.filter((data) => data.draft)?.length || 0;
  }, [unArchivedData]);

  const finishedCount = useMemo(() => {
    return (
      unArchivedData.filter(
        (data) => data.completedDubs && data.totalDubs === data.completedDubs
      )?.length || 0
    );
  }, [unArchivedData]);

  const pendingReviewCount = useMemo(() => {
    return (
      unArchivedData.filter(
        (data) => data.waitingForApproval || data.uploadFailed
      )?.length || 0
    );
  }, [unArchivedData]);

  const onGoingCount = useMemo(() => {
    return (
      unArchivedData?.length - (draftCount + finishedCount + pendingReviewCount)
    );
  }, [unArchivedData, draftCount, finishedCount, pendingReviewCount]);

  const archivedCount = useMemo(() => {
    return allProjects.filter((project) => project.archived)?.length || 0;
  }, [allProjects]);

  const handleUpgrade = () => {
    dispatch(setPricingDialog(true));
    trackMixpanelEvent(MIXPANEL_EVENTS.UPGRADE_START, {
      "Entry Point": "Sidebar"
    });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        minWidth: 316,
        borderRadius: 0,
        borderRight: 1,
        borderColor: "divider",
        position: "relative"
      }}
    >
      <Stack
        height={"calc(100% - 64px)"}
        justifyContent={"space-between"}
        p={1}
        sx={{
          maxHeight: "-webkit-fill-available",
          overflow: "auto"
        }}
      >
        <Stack>
          <ListItemButton
            onClick={() => setExpandProjectsMenu((state) => !state)}
            disableGutters
            sx={{
              px: 2,
              py: 1,
              gap: 1
            }}
          >
            <FolderSpecialRounded color="secondary" />
            <ListItemText primary="Projects" />
            {expandProjectsMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={expandProjectsMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => {
                  navigate("/home");
                }}
                selected={!currentActiveTab}
              >
                <ListItemText
                  primary={`All Projects (${allProjects?.length || 0})`}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => {
                  navigate(
                    `/home?${STATUS_FILTER}=${QUERY_PARAMS_FILTERS.DRAFT}`
                  );
                }}
                selected={currentActiveTab === QUERY_PARAMS_FILTERS.DRAFT}
              >
                <ListItemText primary={`Draft (${draftCount})`} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => {
                  navigate(
                    `/home?${STATUS_FILTER}=${QUERY_PARAMS_FILTERS.ON_GOING}`
                  );
                }}
                selected={currentActiveTab === QUERY_PARAMS_FILTERS.ON_GOING}
              >
                <ListItemText primary={`On-going (${onGoingCount})`} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => {
                  navigate(
                    `/home?${STATUS_FILTER}=${QUERY_PARAMS_FILTERS.PENDING_REVIEW}`
                  );
                }}
                selected={
                  currentActiveTab === QUERY_PARAMS_FILTERS.PENDING_REVIEW
                }
              >
                <ListItemText
                  primary={`Pending Review (${pendingReviewCount})`}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => {
                  navigate(
                    `/home?${STATUS_FILTER}=${QUERY_PARAMS_FILTERS.FINISHED}`
                  );
                }}
                selected={currentActiveTab === QUERY_PARAMS_FILTERS.FINISHED}
              >
                <ListItemText primary={`Finished (${finishedCount})`} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 6 }}
                onClick={() => {
                  navigate(
                    `/home?${STATUS_FILTER}=${QUERY_PARAMS_FILTERS.ARCHIVED}`
                  );
                }}
                selected={currentActiveTab === QUERY_PARAMS_FILTERS.ARCHIVED}
              >
                <ListItemText primary={`Archived (${archivedCount})`} />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            onClick={() => setExpandSettingsMenu((state) => !state)}
            disableGutters
            sx={{
              px: 2,
              py: 1,
              gap: 1,
              mt: 3
            }}
          >
            <SettingsRounded color="secondary" />
            <ListItemText primary="Settings" />
            {expandProjectsMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={expandSettingsMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense>
              <ListItemButton
                href="/profile/workspace"
                sx={{ pl: 6 }}
                selected={currentActiveTab === "WORKSPACE"}
              >
                <ListItemText primary="Workspace Settings" />
              </ListItemButton>
              <ListItemButton
                href="/profile/workspace/manage"
                sx={{ pl: 6 }}
                selected={currentActiveTab === "WORKSPACE_MANAGE"}
              >
                <ListItemText primary="User Management" />
              </ListItemButton>
              <ListItemButton
                href="/profile/user"
                sx={{ pl: 6 }}
                selected={currentActiveTab === "PROFILE_USER"}
              >
                <ListItemText primary="Profile Settings" />
              </ListItemButton>
              <ListItemButton
                href="/profile/workspace/credit-utilization"
                sx={{ pl: 6 }}
                selected={currentActiveTab === "CREDIT_UTILIZATION"}
              >
                <ListItemText primary="Credit Consumption & Plan" />
              </ListItemButton>
            </List>
          </Collapse>
        </Stack>
        {/* Credit Upgrade */}
        <Stack gap={1.5} sx={{ justifySelf: "end" }} padding={"20px"}>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Typography variant="body2">Credits left</Typography>
            <Stack direction={"row"} alignItems={"center"} gap={0.25}>
              <CreditsIcon
                style={{
                  height: 14,
                  width: 14
                }}
              />
              <Typography variant="subtitle2" color={"success.main"}>
                {availableCredits}
              </Typography>
            </Stack>
          </Stack>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<UpgradeRounded sx={{ width: 24, height: 24 }} />}
            onClick={handleUpgrade}
          >
            Upgrade
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ClientSideBar;
