import { paymentCardImage } from "@/utils/links";
import { PLANS_CATEGORY, PlanSummary, PricingPlan } from "../types";
import CheckIcon from "@mui/icons-material/Check";
import { KEY_BOARD_KEYS } from "@/utils/misc";
// import { KEY_BOARD_KEYS } from "@/constants";

export const enum PRICING_STEP {
  FUND_SETUP = "fundSetup",
  CHECKOUT = "checkout",
  PAYMENT_SUCCESS = "paymentSuccess"
}

export const PLAN_INFO: Record<
  PLANS_CATEGORY,
  {
    title: string;
    subTitle: string | React.ReactNode;
    planSummary: PlanSummary<string | ((arg: PricingPlan) => string)>[];
  }
> = {
  [PLANS_CATEGORY.FREE]: {
    title: "Free Trial",
    subTitle: (
      <>
        Explore all capabilities for free
        <br />
        No Credit card required
      </>
    ),
    planSummary: [
      {
        text: `Automated dubbing`,
        icon: <CheckIcon />
      },
      {
        text: `2 credits per minute per dub`,
        icon: <CheckIcon />
      },
      {
        text: `Standard support`,
        icon: <CheckIcon />
      },
      {
        text: (plan) => `${plan.noOfProject || 1} project at a time`,
        icon: <CheckIcon />
      }
    ]
  },
  [PLANS_CATEGORY.PAY_AS_YOU_GO]: {
    title: "Pay as you go",
    subTitle: "Flexible plan for varying needs, starting as low as",
    planSummary: [
      {
        text: "No watermarks",
        icon: <CheckIcon />
      },
      {
        text: "Editing dubbed outputs",
        icon: <CheckIcon />
      },
      {
        text: (plan) => `${plan.noOfProject || 5} projects at a time`,
        icon: <CheckIcon />
      }
    ]
  },
  [PLANS_CATEGORY.CUSTOM]: {
    title: "Enterprise",
    subTitle: "Personalise your plan as per your tailored requirements",
    planSummary: [
      {
        text: "QA assured dubbing",
        icon: <CheckIcon />
      },
      {
        text: "Dedicated customer support",
        icon: <CheckIcon />
      },
      {
        text: "Unlimited projects",
        icon: <CheckIcon />
      },
      {
        text: "Scalable pricing with volume-based discounts",
        icon: <CheckIcon />
      },
      {
        text: "Sharing & Collaboration",
        icon: <CheckIcon />,
        tooltipText: "Review, Comment & Edit with your team"
      },
      {
        text: "Single sign-on (SSO)",
        icon: <CheckIcon />
      },
      {
        text: "Security assessments",
        icon: <CheckIcon />
      },
      {
        text: "Master Service Agreement",
        icon: <CheckIcon />,
        tooltipText:
          "Our standard template for providing services; Redlining enabled for minimum deal value."
      },
      {
        text: "PO & invoicing",
        icon: <CheckIcon />
      }
    ]
  }
};

export const CARD_BRANDS: Record<string, string> = {
  visa: paymentCardImage("visa.png"),
  mastercard: paymentCardImage("master-card.png"),
  amex: paymentCardImage("amex.png"),
  cartes_bancaires: paymentCardImage("cartes-bancaires.png"),
  discover: paymentCardImage("discover.png"),
  diners: paymentCardImage("diners-club-international.png"),
  jcb: paymentCardImage("jcb.png"),
  unionpay: paymentCardImage("union-pay.png"),
  unknown: paymentCardImage("generic-card.png")
};

export const CHECKOUT_COUPON_ERRORS: Record<string, string> = {
  INVALID_COUPON_CODE: "INVALID_COUPON_CODE",
  INVALID_COUPON_CODE_MURF_VALIDATION: "INVALID_COUPON_CODE_MURF_VALIDATION",
  INVALID_COUPON_EXPIRED: "INVALID_COUPON_EXPIRED",
  INVALID_COUPON_SEATS: "INVALID_COUPON_SEATS",
  INVALID_COUPON_YEARLY: "INVALID_COUPON_YEARLY"
};

export const CHECKOUT_PREVIEW_ERRORS: Record<string, string> = {
  NEGATIVE_TOTAL: "NEGATIVE_TOTAL",
  INVALID_COUPON_CODE: "INVALID_COUPON_CODE",
  INVALID_COUPON_CODE_MURF_VALIDATION: "INVALID_COUPON_CODE_MURF_VALIDATION",
  INVALID_COUPON_EXPIRED: "INVALID_COUPON_EXPIRED",
  INVALID_COUPON_SEATS: "INVALID_COUPON_SEATS",
  INVALID_COUPON_YEARLY: "INVALID_COUPON_YEARLY",
  INVALID_TAX_VALUE: "INVALID_TAX_VALUE"
};

export const validateNumberOnKeyDown = (
  e: React.KeyboardEvent<HTMLDivElement>
) => {
  return [
    KEY_BOARD_KEYS.Dot,
    KEY_BOARD_KEYS.Hyphen,
    KEY_BOARD_KEYS.Plus
  ].includes(e.key);
};
