import { UserAvatar } from "@/components/elements";
import { useTypedSelector } from "@/config/configureAppStore";
import { useIssuesComments } from "@/features/comments/hooks";
import { IconButton } from "@mui/material";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

interface DubPlayerCommentButtonProps {
  issueId: string;
  dubId: string;
}

export function DubPlayerCommentButton({
  issueId,
  dubId
}: DubPlayerCommentButtonProps) {
  const [searchParam, setSearchParams] = useSearchParams();

  const currentIssue = useTypedSelector(
    (state) => state.issues.allIssues?.[dubId]?.[issueId]
  );
  const { getCreatedBy } = useIssuesComments();

  const currentIssueCreator = useMemo(() => {
    return getCreatedBy(currentIssue?.createdBy);
  }, [currentIssue?.createdBy, getCreatedBy]);

  const isSelected = useMemo(() => {
    return searchParam.get("issue") == issueId;
  }, [issueId, searchParam]);

  if (!currentIssue) {
    return null;
  }

  return (
    <IconButton
      onClick={() => {
        setSearchParams(
          (prev) => {
            prev.set("issue", issueId);
            return prev;
          },
          {
            replace: true
          }
        );
      }}
      sx={{
        m: "2px",
        p: 0,
        backgroundColor: isSelected ? "info.main" : "common.white",
        "&:hover": {
          backgroundColor: isSelected ? "info.main" : "common.white"
        }
      }}
      title={issueId}
    >
      <UserAvatar
        displayName={currentIssueCreator?.displayName}
        avatarUrl={currentIssueCreator?.avatarUrl}
        sx={{
          height: 28,
          width: 28,
          borderStyle: "solid",
          borderWidth: isSelected ? 4 : 2,
          borderColor: isSelected ? "info.main" : "common.white"
        }}
        typographyProps={{
          sx: {
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: 1.66,
            letterSpacing: "0.2px"
          }
        }}
      />
    </IconButton>
  );
}
