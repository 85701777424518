import { api } from "@/lib/axios";
import { APIResponse } from "@/types/apiResponse";
import { ExportConfig } from "../types/export";

export interface FetchDetailedProjectParams {
  projectId: string;
}

export interface FetchFileStatusParams {
  projectId: string;
  fileIds: string[];
}

interface ExportDubsParams {
  projectId: string;
  fileId: string;
  dubId?: string;
  dubVersion?: string;
  exportConfig: ExportConfig;
  signal: AbortSignal;
}

export const projectDetailsApi = {
  fetchDetailedProject: ({ projectId }: FetchDetailedProjectParams) =>
    api.get(`/project/${projectId}`),
  fetchSourceFiles: ({ projectId }: FetchDetailedProjectParams) =>
    api.get(`project/${projectId}/get-source-files`),
  deleteEmptyProject: ({ projectId }: FetchDetailedProjectParams) =>
    api.delete(`project/${projectId}/delete`),
  downloadAllDubs: ({
    fileId,
    projectId
  }: {
    fileId: string;
    projectId: string;
  }) =>
    api.get<APIResponse<null>>(
      `project/${projectId}/download-all-dubs/file/${fileId}`
    ),
  downloadAllDubsStatus: ({
    fileId,
    projectId,
    taskId
  }: {
    fileId: string;
    projectId: string;
    taskId: string;
  }) =>
    api.get<APIResponse<null>>(
      `project/${projectId}/download-all-dubs-status/file/${fileId}/task/${taskId}`
    ),
  exportDubs: ({ projectId, signal, ...rest }: ExportDubsParams) =>
    api.post<APIResponse<null>>(
      `project/${projectId}/export-dubs`,
      {
        ...rest
      },
      { signal }
    ),
  exportDubsStatus: ({
    projectId,
    taskId
  }: {
    projectId: string;
    taskId: string;
  }) =>
    api.get<APIResponse<null>>(
      `project/${projectId}/export-dubs-status/task/${taskId}`
    ),
  fetchSourceFileStatus: ({ projectId, fileIds }: FetchFileStatusParams) =>
    api.post(`project/${projectId}/source-file-status`, fileIds),
  fetchDubStatus: ({ projectId, fileIds }: FetchFileStatusParams) =>
    api.post(`project/${projectId}/all-dub-status`, fileIds)
};
