import { KeyboardEvent } from "react";
import { emailRegex, passwordRegex } from "./regex";

let counter = 0;
let minTimestamp = 0;

export const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, "0");

export const generateId = (prefix?: string) => {
  const suffix = counter++;
  if (suffix === 9 || minTimestamp === 0) {
    counter = 0;
    const now = Date.now();
    if (now <= minTimestamp) {
      minTimestamp++;
    } else {
      minTimestamp = now;
    }
  }
  const uuid =
    prefix +
    zeroPad(minTimestamp, 14) +
    suffix +
    Math.random().toString(36).slice(2).substr(0, 2).toUpperCase();

  return uuid;
};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (...args: Parameters<T>): void {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export const clamp = (number: number, min: number, max: number) => {
  return Math.max(min, Math.min(number, max));
};

export const toFixedInteger = (number: number, value = 2) =>
  (number.toFixed(value) as unknown as number) * 1;

export const nextTick = (fn: () => void, delay = 0) => {
  setTimeout(fn, delay);
};

export const getPercentage = (value: number, total: number) => {
  return toFixedInteger((value * 100) / total);
};

export const getValueFromPercentage = (percentage: number, total: number) => {
  return toFixedInteger((percentage * total) / 100);
};

export const validateEmail = (email: string) => {
  return emailRegex.test(email);
};

export const validatePassword = (password: string) =>
  password.match(passwordRegex);

// according to e.key
export const KEY_BOARD_KEYS = {
  "@": "@",
  Escape: "Escape",
  Space: " ",
  ArrowUp: "ArrowUp",
  ArrowDown: "ArrowDown",
  Backspace: "Backspace",
  Enter: "Enter",
  Tab: "Tab",
  ArrowRight: "ArrowRight",
  ArrowLeft: "ArrowLeft",
  Dot: ".",
  Hyphen: "-",
  Plus: "+"
};

export const downloadFileUsingUrl = (downloadUrl: string, fileName: string) => {
  const anchorEl = document.createElement("a");
  anchorEl.href = downloadUrl;
  anchorEl.target = "_blank";
  anchorEl.download = fileName;
  document.body.appendChild(anchorEl);
  anchorEl.click();
  document.body.removeChild(anchorEl);
};

/**
 *
 * @param event - event
 * @param handleSubmit - function to handle the submit
 * @param disabled - if input is disabled
 */
export const enter2Submit = (
  event: KeyboardEvent,
  handleSubmit: () => void,
  disabled = false
) => {
  if (!disabled && event.key === KEY_BOARD_KEYS.Enter) {
    event.preventDefault();
    handleSubmit();
  }
};

export const getFileSizeInText = (fileSize: number): string => {
  if (!fileSize) {
    return "0 KB";
  }
  let _fileSize;
  const KB = 1000;
  if (fileSize < KB * KB) {
    _fileSize = Number(fileSize / KB).toFixed(2);
    return `${_fileSize} KB`;
  }
  if (fileSize < KB * KB * KB) {
    _fileSize = Number(fileSize / (KB * KB)).toFixed(2);
    return `${_fileSize} MB`;
  }
  _fileSize = Number(fileSize / (KB * KB * KB)).toFixed(2);
  return `${_fileSize} GB`;
};

/**
 * Function to pluralize a word based on the given number.
 * @param number The number to determine plurality.
 * @param word The word to be pluralized.
 * @param definePlural callback which will give the pluralized word
 * @returns The word with 's' appended if the number is not equal to 1.
 */
export const pluralize = (
  number: number,
  word: string,
  definePlural?: (isPlural: boolean) => string,
  returnOnlyText?: boolean
): string => {
  // Check if the number is equal to 1
  const isPlural = number !== 1;

  const pluralisedWord = `${
    definePlural ? definePlural(isPlural) : `${word}${isPlural ? "s" : ""}`
  }`;

  if (returnOnlyText) {
    return pluralisedWord;
  }

  return `${number} ${pluralisedWord}`;
};

/**
 * function used to separate text and numeric part
 * @param name
 * @param splittedArray - if true returns splitted array with initial and numeric part
 * @returns - separated name eg. speaker1 (speaker 1)
 */
export function separateNumericPart(name: string, splittedArray = false) {
  // Use regular expression to separate numeric part from the string
  const match = name.match(/(\D+)(\d+)/);

  if (match) {
    // Extract non-numeric and numeric parts
    const nonNumericPart = match[1];
    const numericPart = match[2];

    if (splittedArray) return [nonNumericPart, numericPart];

    // Concatenate with a space
    const resultString = nonNumericPart + " " + numericPart;

    return [resultString];
  } else {
    // If no numeric part found, return the original string
    return [name];
  }
}

export const detectOs = () => {
  const userAgent = window.navigator.userAgent;
  let os = "Unknown";

  if (userAgent.indexOf("Win") !== -1) {
    os = "Windows OS";
  } else if (userAgent.indexOf("Mac") !== -1) {
    os = "Macintosh";
  } else if (userAgent.indexOf("Linux") !== -1) {
    os = "Linux OS";
  } else if (userAgent.indexOf("Android") !== -1) {
    os = "Android OS";
  } else if (userAgent.indexOf("like Mac") !== -1) {
    os = "IOS";
  }
  return os;
};

export const getInitials = (name: string) => {
  return name.split(" ").reduce((acc, val) => acc + val[0], "");
};
