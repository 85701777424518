import { STATUS } from "@/constants/status";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { storageService } from "@/utils/storage";

export type ThemeModes = "system" | "light" | "dark";

type TGlobalState = {
  appInitializationStatus: STATUS;
  appTheme: ThemeModes;
  chatAvailable?: boolean;
};

/**
 * function to validate the theme stored in local storage
 */
const getUserTheme = () => {
  const theme = storageService.getItem("theme");
  if (theme === "light" || theme === "system") return theme;
  // default theme for murf dub is dark
  return "dark";
};

const initialState: TGlobalState = {
  appInitializationStatus: STATUS.IDLE,
  appTheme: getUserTheme(),
  chatAvailable: true
};

const globalSlice = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    updateAppInitializationStatus: (state, action: PayloadAction<STATUS>) => {
      state.appInitializationStatus = action.payload;
    },

    updateAppTheme: (state, action: PayloadAction<ThemeModes>) => {
      state.appTheme = action.payload;
    }
  }
});

export const { updateAppInitializationStatus, updateAppTheme } =
  globalSlice.actions;

export default globalSlice.reducer;
