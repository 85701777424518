import { api } from "@/lib/axios";

interface IContactSales {
  workspaceId: string;
  eventSource: string;
}

export const creditsApi = {
  contactSales: ({ workspaceId, eventSource }: IContactSales) =>
    api.get(`/user/markSalesEvents/${workspaceId}/${eventSource}
    `)
};
