import { LANGUAGES } from "@/constants/languages";
import { api } from "@/lib/axios";
import { APIResponse } from "@/types/apiResponse";

type SharedMediaResponse = {
  audioOnly: boolean;
  dubbedVttUrl: string;
  extra: string | null;
  fileName: string;
  hideMurfBranding: boolean;
  originalAudioUrl: string;
  previewUrl: string;
  responseCode: string;
  responseMessage: string;
  sourceLocale: LANGUAGES;
  targetLocale: LANGUAGES;
  thumbnailUrl: string;
  transcriptVttUrl: string;
};

const unauthenticatedApi = {
  fetchSharedMedia: ({
    shareId,
    password
  }: {
    shareId: string;
    password?: string;
  }) =>
    api.post<APIResponse<SharedMediaResponse>>(
      `ping/shared-media/${shareId}`,
      password,
      {
        headers: {
          "Content-Type": "text/plain"
        }
      }
    )
};

export default unauthenticatedApi;
