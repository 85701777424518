import { RouteObject } from "react-router-dom";
import SharedDub from "./SharedDub";

const unauthenticatedRoutes: RouteObject[] = [
  {
    path: "u",
    children: [
      {
        path: "share/:shareId",
        element: <SharedDub />
      }
    ]
  }
];

export default unauthenticatedRoutes;
